import { Stack, Typography } from "@mui/material"
import CoverImageSecurityItem from "./CoverImageSecurityItem"

const CoverImageSecurity5 = () => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      sx={{
        gap: { xs: "6px", sm: "12px" },
        backgroundColor: "white",
        borderRadius: "8px",
      }}
    >
      <CoverImageSecurityItem imgSrc="/assets/icons/security/security.svg">
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Compra
        </Typography>
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Segura
        </Typography>
      </CoverImageSecurityItem>

      <CoverImageSecurityItem imgSrc="/assets/icons/security/satisfaction.svg">
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Satisfação
        </Typography>
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Garantida
        </Typography>
      </CoverImageSecurityItem>

      <CoverImageSecurityItem imgSrc="/assets/icons/security/privacy.svg">
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Privacidade
        </Typography>
        <Typography fontSize="14px" fontWeight={600} lineHeight="18px">
          Protegida
        </Typography>
      </CoverImageSecurityItem>
    </Stack>
  )
}

export default CoverImageSecurity5
