import { List, Stack, Typography } from "@mui/material";
import { IOrderBump } from "../../../../core/types/OrderBump";
import React from "react";
import CheckoutOrderBump4 from "./OrderBump";

export interface ICheckoutOrderBumps4 {
  paymentInstallment: number;
  paymentMethodType: number;
  orderBumps?: IOrderBump[];
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>;
}

const CheckoutOrderBumps4 = ({
  paymentInstallment,
  paymentMethodType,
  orderBumps,
  orderBumpChange,
}: ICheckoutOrderBumps4) => {
  const quantity = orderBumps?.length ?? 0;
  const isOne = quantity <= 1;

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        pt: 2,
      }}
    >
      <Stack alignItems="center">
        <Typography
          color="#38393B"
          fontWeight={700}
          fontSize="24px"
          lineHeight="32px"
        >
          {`🎉 Você possui ${quantity} oferta${isOne ? "" : "s"}!`}
        </Typography>
        <Typography
          color="#707275"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          Oportunidade única de adquirir produtos incríveis com um super
          desconto!
        </Typography>
      </Stack>
      <List sx={{ width: "100%", m: 0, p: 0, margin: 0 }}>
        {orderBumps &&
          orderBumps.map((orderBump) => (
            <CheckoutOrderBump4
              key={`CheckoutOrderBump3${orderBump.id}`}
              paymentInstallment={paymentInstallment}
              paymentMethodType={paymentMethodType}
              orderBump={orderBump}
              onChange={orderBumpChange}
            />
          ))}
      </List>
    </Stack>
  );
};

export default CheckoutOrderBumps4;
