import { IconButton, MenuItem, Stack } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { TSale } from "../../../../core/types/Sale";
import TextFieldRequired from "../../../Inputs/TextFieldRequired";
import { numberOnly } from "../../../../masks/masks";
import SelectRequired from "../../../Inputs/SelectRequired";
import states from "../../../../core/data/states";
import React from "react";

export interface ICheckoutAddress2 {
    control: Control<TSale, any>;
    loading: boolean;
    searchedZipCode: boolean;
    watch: UseFormWatch<TSale>;
    saveLostSale: () => void;
    clearErrors: () => void;
    searchZipCode: (loading: boolean, zipCode: string) => void;
    handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
    handleAddresComplementKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const CheckoutAddress2 = ({
    control,
    loading,
    searchedZipCode,
    watch,
    saveLostSale,
    clearErrors,
    searchZipCode,
    handleAddresNumberKeyDown,
    handleAddresComplementKeyDown,
}: ICheckoutAddress2) => {
    return (
        <Stack
            direction="column"
            spacing={2}
        >
            <TextFieldRequired
                control={control}
                fieldName="address.zipCode"
                label="CEP"
                placeholder="00000-000"
                inputProps={{ inputMode: "numeric", maxLength: 9 }}
                onFieldExit={saveLostSale}
                clearErrors={clearErrors}
                validate={(value) => (!value) || (value == '') ? true : numberOnly(value).length === 8}
                endAdornment={
                    <IconButton
                        size="small"
                        onClick={() => searchZipCode(loading, watch("address.zipCode"))}
                    >
                        <SearchIcon />
                    </IconButton>
                }
            />

            <Stack
                direction="column"
                spacing={2}
                sx={{ display: searchedZipCode ? "block" : "none" }}
            >
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextFieldRequired
                        control={control}
                        fieldName="address.street"
                        label="Endereço"
                        clearErrors={clearErrors}
                    />
                    <TextFieldRequired
                        control={control}
                        fieldName="address.number"
                        label="Número"
                        onKeyDown={handleAddresNumberKeyDown}
                        clearErrors={clearErrors}
                    />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextFieldRequired
                        control={control}
                        fieldName="address.neighborhood"
                        label="Bairro"
                        clearErrors={clearErrors}
                    />
                    <TextFieldRequired
                        control={control}
                        fieldName="address.complement"
                        label="Complemento"
                        required={false}
                        onKeyDown={handleAddresComplementKeyDown}
                        clearErrors={clearErrors}
                    />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <TextFieldRequired
                        control={control}
                        fieldName="address.city"
                        label="Cidade"
                        clearErrors={clearErrors}
                    />
                    <SelectRequired
                        control={control}
                        fieldName="address.state"
                        label="Estado"
                    >
                        {states.map((state) => (
                            <MenuItem value={state.sigla}>{state.nome}</MenuItem>
                        ))}
                    </SelectRequired>
                </Stack>
            </Stack>
        </Stack>
    );
}

export default CheckoutAddress2;