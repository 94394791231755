import { Block, BlockNoteEditor } from "@blocknote/core";
import { BlockNoteView, useBlockNote } from "@blocknote/react";
import { useEffect } from "react";
import "./blocknote.css";

type WindowWithProseMirror = Window & typeof globalThis & { ProseMirror: any };

export interface ICheckoutTextEditorProps {
    initialContent: string;
}

function CheckoutTextView({ initialContent }: ICheckoutTextEditorProps) {
    const getInitialContent = () => {
        try {
            return JSON.parse(initialContent);
        } catch (e) {
            return [];
        }
    };

    // Creates a new editor instance.
    const view: BlockNoteEditor = useBlockNote({
        initialContent: getInitialContent(),
        editable: false,
    });

    useEffect(() => {
        if (view) {
            const blocks: Block[] = getInitialContent();
            const topLevelBlocks = view.topLevelBlocks;
            const lastBlock = topLevelBlocks.length;
            if (lastBlock) {
                view.insertBlocks(blocks, { id: topLevelBlocks[lastBlock - 1].id });
                view.removeBlocks(topLevelBlocks);
            }
        }
    }, [initialContent]);

    // Give tests a way to get prosemirror instance
    (window as WindowWithProseMirror).ProseMirror = view?._tiptapEditor;

    // Renders the editor instance using a React component.
    return <BlockNoteView editor={view} theme="light" />;
}

export default CheckoutTextView;
