import TagFunctions from "./TagFunctions";

//const tagHotjarAsync = "//code.jivosite.com/widget/XU0sEqryZ7";

const tagHotjarHeader = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3525035,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

const addTagsHotjar = () => {
    //const tagCode = TagFunctions.addScriptHeaderAsync(tagHotjarAsync);
    const tagHotjar = TagFunctions.addScriptHeader(tagHotjarHeader);
    //return [tagCode, tagHotjar];
    return [tagHotjar];
}

const HotjarFunctions = {
    addTagsHotjar,
}

export default HotjarFunctions;