import { Box, IconButton, MenuItem, Stack } from "@mui/material"
import { Control, UseFormWatch } from "react-hook-form"
import SearchIcon from "@mui/icons-material/Search"
import { TSale } from "../../../../core/types/Sale"
import TextFieldRequired from "../../../Inputs/TextFieldRequired"
import { numberOnly } from "../../../../masks/masks"
import SelectRequired from "../../../Inputs/SelectRequired"
import states from "../../../../core/data/states"
import React from "react"

export interface ICheckoutAddress5 {
  control: Control<TSale, any>
  loading: boolean
  searchedZipCode: boolean
  watch: UseFormWatch<TSale>
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  onSendPixel: () => void
}

const CheckoutAddress5 = ({
  control,
  loading,
  searchedZipCode,
  watch,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresNumberKeyDown,
  handleAddresComplementKeyDown,
  onSendPixel,
}: ICheckoutAddress5) => {
  return (
    <Stack direction="column" spacing={2} data-cy="containerAddress">
      <TextFieldRequired
        size="small"
        control={control}
        fieldName="address.zipCode"
        label="CEP"
        placeholder="00000-000"
        inputProps={{
          inputMode: "numeric",
          maxLength: 9,
          "data-cy": "addressZipCode",
          "data-testid": "addressZipCode",
        }}
        dataCyErrorLabel="addressZipCodeError"
        onFieldExit={saveLostSale}
        clearErrors={clearErrors}
        validate={(value) =>
          !value || value == "" ? true : numberOnly(value).length === 8
        }
        endAdornment={
          <IconButton
            size="small"
            onClick={() => searchZipCode(loading, watch("address.zipCode"))}
          >
            <img alt="Search" src="/assets/icons/common/search.svg" />
          </IconButton>
        }
      />

      <Stack
        direction="column"
        spacing={2}
        sx={{ display: searchedZipCode ? "block" : "none" }}
      >
        <TextFieldRequired
          size="small"
          control={control}
          fieldName="address.street"
          label="Endereço"
          clearErrors={clearErrors}
          inputProps={{
            maxLength: 64,
            "data-cy": "addressStreet",
            "data-testid": "addressStreet",
          }}
          dataCyErrorLabel="addressStreetError"
        />

        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          <TextFieldRequired
            size="small"
            control={control}
            fieldName="address.number"
            label="Número"
            onKeyDown={handleAddresNumberKeyDown}
            clearErrors={clearErrors}
            inputProps={{
              "data-cy": "addressNumber",
              "data-testid": "addressNumber",
            }}
            dataCyErrorLabel="addressNumberError"
          />

          <Box sx={{ display: { xs: "block", sm: "none" } }} />

          <TextFieldRequired
            size="small"
            control={control}
            fieldName="address.complement"
            label="Complemento"
            required={false}
            onKeyDown={handleAddresComplementKeyDown}
            clearErrors={clearErrors}
          />
        </Stack>
        <TextFieldRequired
          size="small"
          control={control}
          fieldName="address.neighborhood"
          label="Bairro"
          clearErrors={clearErrors}
          inputProps={{
            "data-cy": "addressNeighborhood",
            "data-testid": "addressNeighborhood",
          }}
          dataCyErrorLabel="addressNeighborhoodError"
        />
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1}>
          <TextFieldRequired
            size="small"
            control={control}
            fieldName="address.city"
            label="Cidade"
            clearErrors={clearErrors}
            onFieldExit={onSendPixel}
            inputProps={{
              "data-cy": "addressCity",
              "data-testid": "addressCity",
            }}
            dataCyErrorLabel="addressCityError"
          />

          <Box sx={{ display: { xs: "block", sm: "none" } }} />

          <SelectRequired
            size="small"
            control={control}
            fieldName="address.state"
            label="Estado"
            onFieldExit={onSendPixel}
            inputProps={{
              "data-cy": "addressState",
              "data-testid": "addressState",
            }}
          >
            {states.map((state) => (
              <MenuItem key={`state${state.sigla}`} value={state.sigla}>
                {state.nome}
              </MenuItem>
            ))}
          </SelectRequired>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default CheckoutAddress5
