import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutFooterDefault from "./Layouts/LayoutDefault/CheckoutFooterDefault"
import CheckoutFooter2 from "./Layouts/Layout2/CheckoutFooter2"
import React from "react"
import CheckoutFooter3 from "./Layouts/Layout3/CheckoutFooter3"
import CheckoutFooter4 from "./Layouts/Layout4/CheckoutFooter4"
import CheckoutFooter5 from "./Layouts/Layout5/CheckoutFooter5"

export interface ICheckoutFooter {
  layout?: TCheckoutLayout
  tenantName?: string
  setOpenTermsPurchase: (value: boolean) => void
}

const CheckoutFooter = ({
  layout,
  tenantName,
  setOpenTermsPurchase,
}: ICheckoutFooter) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutFooterDefault
          tenantName={tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutFooter2
          tenantName={tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutFooter3
          tenantName={tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutFooter4
          tenantName={tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutFooter5
          tenantName={tenantName}
          setOpenTermsPurchase={setOpenTermsPurchase}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutFooter
