import { Stack, Typography } from "@mui/material"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../core/types/Product"
import { TSale, TSaleProduct } from "../../../../core/types/Sale"
import React from "react"
import { UseFormWatch } from "react-hook-form"
import NumberFunctions from "../../../../core/functions/NumberFunctions"
import OrderItem5 from "./OrderDetails/OrderItem5"

export interface ICheckoutDetails5 {
  data: IProductPrice | null
  products: TSaleProduct[]
  paymentMethodType: number
  paymentInstallment: number
  productInstallments?: IProductPricePaymentInstallment[] | null
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
}

const CheckoutDetails5 = ({
  data,
  products,
  paymentMethodType,
  paymentInstallment,
  productInstallments,
  paymentTotal,
  paymentValue,
  watch,
}: ICheckoutDetails5) => {
  return (
    <Stack spacing={2} sx={{ pt: 1 }}>
      <OrderItem5
        subtitle
        paymentMethodType={paymentMethodType}
        installmentNumber={paymentInstallment}
        description="Valor Total:"
        value={paymentTotal}
        defaultValue={paymentValue}
        installments={null}
        typePayment={data?.typePayment}
      />
    </Stack>
  )
}

export default CheckoutDetails5
