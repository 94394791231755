import { Stack, Typography } from "@mui/material";
import React from "react";
import StepPaymentItem from "./StepPaymentItem/StepPaymentItem";

const PixPaymentPrice = ({ visible }: { visible: boolean }) => {
    return (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} sx={{ display: visible ? undefined : "none" }}>
            <StepPaymentItem
                src="/assets/icons/payments/pix/approved.svg"
                title="Aprovação imediata"
                subtitle="O pagamento com Pix leva pouco tempo para ser processado."
            />
            <StepPaymentItem
                src="/assets/icons/payments/pix/security.svg"
                title="Transação segura"
                subtitle="O Pix foi desenvolvido pelo Banco Central para facilitar suas compras, garantindo a proteção dos seus dados."
            />
            <StepPaymentItem
                src="/assets/icons/payments/pix/payment.svg"
                title="Finalize sua compra com facilidade"
                subtitle="É só acessar a área Pix no aplicativo do seu banco e escanear o QR code ou digitar o código."
            />
        </Stack>
    );
};

export default PixPaymentPrice;
