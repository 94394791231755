import { Stack, Typography } from "@mui/material";
import React from "react";
import StepPaymentItem from "./StepPaymentItem/StepPaymentItem";

const BoletoPaymentPrice4 = ({ visible }: { visible: boolean }) => {
    return (
        <Stack direction={{ xs: "column", sm: "row" }} spacing={1} sx={{ display: visible ? undefined : "none" }}>
            <StepPaymentItem
                src="/assets/icons/payments/ticket/date.svg"
                title="Pague até a data de vencimento"
                subtitle="Faça o pagamento até a data de vencimento e garanta seu acesso ao produto."
            />
            <StepPaymentItem
                src="/assets/icons/payments/ticket/timer.svg"
                title="Aguarde a aprovação do pagamento"
                subtitle="Pagamentos com boleto levam até 3 dias úteis para serem compensados."
            />
            <StepPaymentItem
                src="/assets/icons/payments/ticket/pix.svg"
                title="Pague com Pix e tenha acesso imediato ao produto"
                subtitle="É só acessar a área Pix no aplicativo do seu banco e escanear o QR code ou digitar o código."
            />
        </Stack>
    );
};

export default BoletoPaymentPrice4;
