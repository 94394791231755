import React from "react"
import { TSaleProduct } from "../../../../../core/types/Sale"
import OrderItem4 from "./OrderItem4"

export interface IOrderProductProp {
  paymentMethodType: number
  installmentNumber: number
  product: TSaleProduct
}

const OrderProduct4 = ({
  paymentMethodType,
  installmentNumber,
  product,
}: IOrderProductProp) => {
  return (
    <OrderItem4
      key={product.id}
      paymentMethodType={paymentMethodType}
      installmentNumber={installmentNumber}
      description={product.productName}
      value={product.firstPurchasePrice || product.value}
      defaultValue={product.firstPurchasePrice || product.value}
      installments={product.installments}
      typePayment={product.typePayment}
      frequency={product.frequency}
    />
  )
}

export default OrderProduct4
