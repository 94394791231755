import * as React from "react"
import { styled } from "@mui/material/styles"
import Button, { ButtonProps } from "@mui/material/Button"
import { grey } from "@mui/material/colors"

const NegativeButton = styled(Button)<ButtonProps>(({ theme, variant }) => ({
  color: theme.palette.getContrastText(grey[500]),
  borderColor: "#707275",
  backgroundColor: variant === "outlined" ? undefined : "#707275",
  "&:hover": {
    backgroundColor: grey[variant === "outlined" ? 200 : 700],
    borderColor: "#707275",
  },
}))

export default NegativeButton
