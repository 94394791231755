const getInfo = () => {
    const userAgent = window.navigator.userAgent;
    const language = window.navigator.language;
    const webdriver = window.navigator.webdriver;
    const cookieEnabled = window.navigator.cookieEnabled;

    let browser;

    if (userAgent.indexOf("Chrome") > -1) {
        browser = "Google Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
        browser = "Apple Safari";
    } else if (userAgent.indexOf("Opera") > -1) {
        browser = "Opera";
    } else if (userAgent.indexOf("Firefox") > -1) {
        browser = "Mozilla Firefox";
    } else if (userAgent.indexOf("MSIE") > -1) {
        browser = "Microsoft Internet Explorer";
    }

    const result = {
        userAgent,
        browser,
        language,
        webdriver,
        cookieEnabled,
    };

    return result;
}

const setGeolocation = (callback: (latitude: number, longitude: number) => void) => {
    window.navigator.geolocation.getCurrentPosition((position) => {
        callback(position.coords.latitude, position.coords.longitude);
    });
}

const BrowserFunctions = {
    getInfo,
    setGeolocation,
}

export default BrowserFunctions;