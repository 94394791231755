import { Paper, Stack, Typography } from "@mui/material";

export interface IStepPaymentItemProps {
    src: string;
    title: string;
    subtitle: string;
}

const StepPaymentItem = ({ src, title, subtitle }: IStepPaymentItemProps) => {
    return (
        <Paper
            elevation={0}
            sx={{ backgroundColor: "#F2F2FF", width: "100%", borderRadius: "8px", p: "16px", gap: "12px" }}
        >
            <Stack direction="column" spacing={1}>
                <img src={src} alt="" style={{ height: "24px", width: "24px", objectFit: "contain" }} />
                <Typography
                    variant="h6"
                    fontWeight={700}
                    fontSize="16px"
                    lineHeight="20px"
                    color="#38393B"
                    sx={{ minHeight: "42px" }}
                >
                    {title}
                </Typography>
                <Typography variant="body2" fontWeight={400} fontSize="12px" lineHeight="16px" color="#707275">
                    {subtitle}
                </Typography>
            </Stack>
        </Paper>
    );
};

export default StepPaymentItem;
