import { List } from "@mui/material";
import { IOrderBump } from "../../../../core/types/OrderBump";
import CheckoutOrderBump2 from "./OrderBump";
import React from "react";

export interface ICheckoutOrderBumps2 {
    orderBumps?: IOrderBump[];
    orderBumpChange: (orderBump: IOrderBump) => Promise<void>;
}

const CheckoutOrderBumps2 = ({ orderBumps, orderBumpChange }: ICheckoutOrderBumps2) => {
    return (
        <List sx={{ width: "100%" }}>
            {orderBumps &&
                orderBumps.map((orderBump) => (
                    <CheckoutOrderBump2
                        orderBump={orderBump}
                        onChange={orderBumpChange}
                    />
                ))}
        </List>
    );
}

export default CheckoutOrderBumps2;