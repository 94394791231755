import { TCustomCheckoutComponentItemSX } from "./CustomCheckout"
import { IProductPrice, IProductPricePaymentInstallment } from "./Product"

export enum TCheckoutLayout {
  None = 0,
  Default = 1,
  Layout2 = 2,
  Layout3 = 3,
  Layout4 = 4,
  Layout5 = 5,
}

export const CHECKOUT_LAYOUT_ALL = [
  TCheckoutLayout.None,
  TCheckoutLayout.Default,
  TCheckoutLayout.Layout2,
  TCheckoutLayout.Layout3,
  TCheckoutLayout.Layout4,
  TCheckoutLayout.Layout5,
]

export enum TCheckoutComponent {
  CoverImage = 1,
  ClientsData = 2,
  AddressData = 3,
  PaymentMethods = 4,
  Orderbumps = 5,
  PurchaseDetails = 6,
  Baseboard = 7,
}

export type TCheckoutInstallmentsContext = {
  main: IProductPricePaymentInstallment[] | undefined
  original: IProductPricePaymentInstallment[] | undefined
  checkout: IProductPricePaymentInstallment[] | undefined
}

export type TCheckoutContext = {
  checkoutSettings: TCustomCheckoutComponentItemSX | null
  setCheckoutSettings: (value: TCustomCheckoutComponentItemSX | null) => void
  productPrice: IProductPrice | null
  setProductPrice: (value: IProductPrice | null) => void
  installment: TCheckoutInstallmentsContext | undefined
  setInstallment: (value: TCheckoutInstallmentsContext | undefined) => void
  pixel: TPixelContext
  setPixel: (value: TPixelContext) => void
  isFacebookPixelSent: (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => boolean
  addFacebookPixelSent: (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => void
}

export type TCheckoutContextItemType = "initiate" | "payment" | "purchase"

export type TPixelContextItem = {
  codeId: string
  type: TCheckoutContextItemType
}

export type TPixelContext = {
  meta: TPixelContextItem[]
}

export type TCheckoutLayoutComponent = {
  checkoutComponent: TCheckoutComponent
  componentStatus: TCheckoutLayout
}

export type TCheckoutAccess = {
  tenantId: string
  tenantName: string
  productId: string
  productPriceId: string
  productCode: string
  productName: string
  checkoutModelId: string
  checkoutModelName: string
  affiliatedCode?: string
  clientIp?: string
  userAgent?: string
  browser?: string
  language?: string
  webdriver?: boolean
  cookieEnabled?: boolean
  lostSaleCode?: string | null
  pageUrl: string
  fbclid?: string | null
  fbp?: string | null
  fbc?: string | null
}

export type TCheckoutOrderAttemptItem = {
  crs?: Date[]
}

export type TCheckoutOrderAttempt = Record<
  string,
  TCheckoutOrderAttemptItem | undefined
>
