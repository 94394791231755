import { BrowserRouter } from "react-router-dom"
import Routes from "./routes"
import { ThemeProvider } from "@emotion/react"
import { CssBaseline, createTheme } from "@mui/material"
import { grey } from "@mui/material/colors"
import React from "react"
import { isWidget } from "./core/constants"
import { CheckoutProvider } from "./core/contexts/CheckoutContext"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import reCAPTCHA from "./core/constants/reCAPTCHA"

export const primaryColor = "#1C52BD"

const themeWeb = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      900: "#1A3579",
      800: "#21469D",
      700: "#3057BE",
    },
    secondary: {
      main: "#F6D240",
      900: "#473800",
      800: "#5C4A00",
      700: "#715D00",
    },
    background: {
      default: grey[50],
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: { fontSize: "3.5rem" },
    h2: { fontSize: "3rem" },
    h3: { fontSize: "2.5rem" },
    h4: { fontSize: "2rem" },
    h5: { fontSize: "1.5rem" },
    h6: { fontSize: "1.25rem" },
  },
  components: {
    /*MuiSelect: {
            styleOverrides: {
                select: {
                    border: `2px solid #BCC1FF`,
                    //padding: 15,
                    borderRadius: "4px",
                },
            },
        },*/
    MuiInputBase: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "4px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "4px",
          borderColor: "#BCC1FF",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "4px",
        },
      },
    },
  },
})

const themeWidget = createTheme({
  palette: {
    primary: {
      main: primaryColor,
      900: "#1A3579",
      800: "#21469D",
      700: "#3057BE",
    },
    secondary: {
      main: "#F6D240",
      900: "#473800",
      800: "#5C4A00",
      700: "#715D00",
    },
    background: {
      default: grey[50],
    },
  },
  typography: {
    fontFamily: "Montserrat",
    h1: { fontSize: "3.5rem" },
    h2: { fontSize: "3rem" },
    h3: { fontSize: "2.5rem" },
    h4: { fontSize: "2rem" },
    h5: { fontSize: "1.5rem" },
    h6: { fontSize: "1.25rem" },
  },
  components: {
    /*MuiSelect: {
            styleOverrides: {
                select: {
                    border: `2px solid ${green[700]}`,
                    padding: 14.5,
                },
            },
        },*/
    MuiInputBase: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "10px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "10px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "::-ms-reveal": {
            display: "none",
          },
          "::-ms-clear": {
            display: "none",
          },
          borderRadius: "10px",
        },
      },
    },
  },
})

function App() {
  const theme = isWidget() ? themeWidget : themeWeb

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <CheckoutProvider>
          <GoogleReCaptchaProvider reCaptchaKey={reCAPTCHA.SITE_KEY}>
            <Routes />
          </GoogleReCaptchaProvider>
        </CheckoutProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App
