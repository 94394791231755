import { List } from "@mui/material";
import { IOrderBump } from "../../../../core/types/OrderBump";
import CheckoutOrderBump from "../../../../pages/Checkout/OrderBump";
import React from "react";

export interface ICheckoutOrderBumpsDefault {
    orderBumps?: IOrderBump[];
    orderBumpChange: (orderBump: IOrderBump) => Promise<void>;
}

const CheckoutOrderBumpsDefault = ({ orderBumps, orderBumpChange }: ICheckoutOrderBumpsDefault) => {
    return (
        <List sx={{ width: "100%" }}>
            {orderBumps &&
                orderBumps.map((orderBump) => (
                    <CheckoutOrderBump
                        orderBump={orderBump}
                        onChange={orderBumpChange}
                    />
                ))}
        </List>
    );
}

export default CheckoutOrderBumpsDefault;