import { CircularProgress, Stack, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "../../../components/Query/useQuery";
import { useCallback, useEffect, useRef } from "react";
import Hosts, { payUrl } from "../../../core/functions/HostFunctions";
import Cookies from "js-cookie";
import { addDays, addMinutes } from "../../../core/functions/dateTime";
import OrderController from "../../../core/controllers/OrderController";
import { TProductLinkAffiliatedCookie } from "../../../core/types/Product";
import BrowserFunctions from "../../../core/functions/BrowserFunctions";
import HostFunctions from "../../../core/functions/HostFunctions";
import ProductController from "../../../core/controllers/ProductController";

const AffiliatedRedirectPage = () => {
    const { codeId } = useParams<{ codeId?: string }>();
    const query = useQuery();

    const getQuery = (name: string) => {
        return query.get(name.toLowerCase()) ?? query.get(name.toUpperCase());
    };

    const linkCode = getQuery("ap");
    const loadingRef = useRef(false);

    const setCookie = (name: string, affiliatedCodeId: string, expires?: number | Date | undefined) => {
        const obj: TProductLinkAffiliatedCookie = {
            cre: new Date().getTime(),
            afid: affiliatedCodeId,
            src: getQuery("src"),
            sck: getQuery("sck"),
            utm_campaign: getQuery("utm_campaign"),
            utm_source: getQuery("utm_source"),
            utm_medium: getQuery("utm_medium"),
            utm_content: getQuery("utm_content"),
            utm_term: getQuery("utm_term"),
        };

        const value = JSON.stringify(obj);
        Cookies.set(name, value, { expires, domain: process.env.REACT_APP_COOKIE_HOST });
    };

    const setLinkPageAccess = useCallback(async (afid?: string, apid?: string | null) => {
        if (afid) {
            const infos = BrowserFunctions.getInfo();
            const ipAddress = await HostFunctions.getIpAddress();
            ProductController.insertProductLinkPageAccess({
                data: {
                    affiliatedCodeId: afid,
                    productLinkCodeId: apid,
                    clientIp: ipAddress,
                    userAgent: infos.userAgent,
                    browser: infos.browser,
                    language: infos.language,
                    pageUrl: window.location.href,
                },
            });
        }
    }, []);

    const getProductLinkAffiliated = useCallback(async (afid?: string, apid?: string | null) => {
        if (!loadingRef.current) {
            loadingRef.current = true;
            setLinkPageAccess(afid, apid);
            const data = await OrderController.getProductLinkAffiliated({
                affiliatedCodeId: afid,
                ap: apid,
            });
            if (data) {
                if (data.otherProducts) {
                    setCookie("__apop", data.affiliatedCodeId, addMinutes(60));
                }

                const expires = addDays(Number(data.duration || 1));
                data.productPriceCodeIds.map((productPriceCodeId) => {
                    setCookie(`_ap_${productPriceCodeId}`, data.affiliatedCodeId, expires);
                    return true;
                });

                setTimeout(() => Hosts.redirect(data.url), 1000);
            } else {
                Hosts.redirect(`${payUrl}/${codeId}${window.location.search}`);
            }
        }
    }, []);

    useEffect(() => {
        getProductLinkAffiliated(codeId, linkCode);
    }, []);

    return (
        <Stack
            direction={"column"}
            width={"100%"}
            sx={{ p: 3 }}
            spacing={3}
            alignItems="center"
            justifyContent="center"
        >
            <Stack direction="column" alignItems="center">
                <Typography variant="body1" fontWeight={500} textAlign="center">
                    Carregando...
                </Typography>
            </Stack>
            <CircularProgress size="2rem" />
        </Stack>
    );
};

export default AffiliatedRedirectPage;
