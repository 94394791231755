import {
  Box,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import ContentDialog from "../../../Dialogs/ContentDialog";
import { IProductPricePaymentInstallment } from "../../../../core/types/Product";
import numbers from "../../../../core/functions/NumberFunctions";
import CountryFunctions from "../../../../core/functions/CountryFunctions";
import { TCountry } from "../../../../core/types/Country";

export interface ICheckoutCoverImage5 {
  imageUrl?: string;
  productImageUrl?: string;
  productName: string;
  productDescription: string;
  productInstallments: IProductPricePaymentInstallment[] | undefined;
  isCheckoutBuilder: boolean;
  productValue: number;
  offerName: string;
}

const CheckoutCoverImage5 = ({
  imageUrl,
  productImageUrl,
  productName,
  productDescription,
  productInstallments,
  isCheckoutBuilder,
  productValue,
  offerName,
}: ICheckoutCoverImage5) => {
  const theme = useTheme();

  const [openDescription, setOpenDescription] = useState(false);
  const [country, setCountry] = useState<TCountry>({
    ...CountryFunctions.countryBrazil,
  });
  const [open, setOpen] = React.useState(false);

  let installment: IProductPricePaymentInstallment | undefined;
  let value =
    productInstallments?.find((pi) => pi.installmentNumber === 1)?.total ??
    productValue;

  productInstallments?.map((productInstallment) => {
    if (
      !installment ||
      installment.installmentNumber < productInstallment.installmentNumber
    ) {
      installment = { ...productInstallment };
    }
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box>
      <Paper
        square={true}
        elevation={0}
        sx={{
          width: "100%",
          borderRadius: {
            xs: "8px",
            sm: "8px",
          },
          py: 3,
        }}
      >
        <Stack direction={"column"} width={"100%"}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="end"
            justifyContent="space-between"
            sx={{ width: "100%", pb: 0.5, px: 3 }}
          >
            <Typography
              color="#EC5040"
              fontSize="16px"
              lineHeight="20px"
              fontWeight={600}
            >
              Você está adquirindo:
            </Typography>
          </Stack>
          <Stack direction="column" sx={{ pl: 3, pr: 3, pt: 1 }} width="100%">
            <Stack
              direction="row"
              spacing={2}
              alignContent="center"
              alignItems="center"
              width="100%"
            >
              <Box sx={{ height: 80, width: 80 }}>
                {productImageUrl && productImageUrl !== "" && (
                  <img
                    src={productImageUrl}
                    alt={productName}
                    style={{
                      height: 80,
                      width: 80,
                      objectFit: "cover",
                      borderRadius: "8px",
                    }}
                  />
                )}
              </Box>
              <Stack
                direction="column"
                spacing={1}
                alignContent="space-between"
                justifyContent="space-between"
                width="100%"
              >
                <Box gap="16px">
                  <Typography
                    color="rgba(93, 94, 97, 1)"
                    fontWeight={700}
                    fontSize="16px"
                    lineHeight="20px"
                  >
                    {productName}
                  </Typography>
                </Box>
                <Stack direction="column">
                  <Stack
                    direction="row"
                    spacing={0.5}
                    alignContent="center"
                    alignItems="end"
                  >
                    {(installment?.installmentNumber ?? 0) > 1 && (
                      <Typography
                        color="rgb(56, 57, 59)"
                        fontWeight={400}
                        fontSize="12px"
                        lineHeight="16px"
                        sx={{ pb: 0.5 }}
                      >
                        {installment?.installmentNumber}x
                      </Typography>
                    )}
                    <Typography
                      color="rgba(56, 57, 59, 1)"
                      fontWeight={700}
                      fontSize="24px"
                      lineHeight="32px"
                    >
                      {numbers.formatMoneyDefault(installment?.value ?? value)}
                    </Typography>
                  </Stack>
                  {(installment?.installmentNumber ?? 0) > 1 && (
                    <Typography
                      color="rgba(74, 75, 78, 1)"
                      fontWeight={400}
                      sx={{ fontSize: 12, lineHeight: "16px" }}
                    >
                      ou {numbers.formatMoneyDefault(value)} à vista
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <ContentDialog
        open={openDescription}
        title={productName}
        hasCancel={false}
        onClose={() => setOpenDescription(false)}
      >
        {productDescription}
      </ContentDialog>
    </Box>
  );
};

export default CheckoutCoverImage5;
