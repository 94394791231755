import { Stack, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/CheckCircle';
import React from "react";

const PixPaymentPrice = ({ visible }: { visible: boolean }) => {
  return (
    <Stack direction="column" spacing={2} sx={{ display: visible ? 'block' : 'none' }}>

      <Stack direction="column">
        <Stack direction="row" spacing={1} alignContent={"center"} alignItems={"center"}>
          <CheckIcon color="primary" />
          <Typography variant="h6">Aprovação imediata</Typography>
        </Stack>
        <Typography variant="body2">O pagamneto com PIX é rápido, leva pouco tempo para ser processado.</Typography>
      </Stack>

      <Stack direction="column">
        <Stack direction="row" spacing={1} alignContent={"center"} alignItems={"center"}>
          <CheckIcon color="primary" />
          <Typography variant="h6">Segurança</Typography>
        </Stack>
        <Typography variant="body2">O PIX foi desenvolvido pelo Banco Central, que garante a segurança e proteção dos seus dados.</Typography>
      </Stack>

      <Stack direction="column">
        <Stack direction="row" spacing={1} alignContent={"center"} alignItems={"center"}>
          <CheckIcon color="primary" />
          <Typography variant="h6">Fácil de usar</Typography>
        </Stack>
        <Typography variant="body2">É só acessar a área PIX no aplicativo do seu banco e escanear o QR code.</Typography>
      </Stack>

    </Stack>
  );
}

export default PixPaymentPrice;
