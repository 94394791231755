import { Box, Grid, Stack, Typography } from "@mui/material"
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
} from "../../core/types/CustomCheckout"
import CheckoutComponenetWidget from "./CheckoutComponentWidget"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../core/types/Product"
import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { TSale, TSaleProduct } from "../../core/types/Sale"
import { IOrderBump } from "../../core/types/OrderBump"

export interface ICheckoutLayout2_3Props {
  item: TCustomCheckoutComponentItem
  id: React.MutableRefObject<string>
  loading: boolean
  loadingVoucher: boolean
  isCheckoutBuilder: boolean
  searchedZipCode: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  productInstallments?: IProductPricePaymentInstallment[]
  installments?: IProductPricePaymentInstallment[]
  orderBumps?: IOrderBump[]
  products: TSaleProduct[]
  paymentInstallment: number
  paymentMethodType: number
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>
  setOpenTermsPurchase: (value: boolean) => void
  onSendPixel: () => void
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

const CheckoutLayout2_3 = ({
  item,
  id,
  loading,
  loadingVoucher,
  isCheckoutBuilder,
  searchedZipCode,
  data,
  control,
  errors,
  productInstallments,
  installments,
  orderBumps,
  products,
  paymentInstallment,
  paymentMethodType,
  paymentTotal,
  paymentValue,
  watch,
  setValue,
  onSubmit,
  onKeyDown,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresComplementKeyDown,
  handleAddresNumberKeyDown,
  orderBumpChange,
  setOpenTermsPurchase,
  onSendPixel,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutLayout2_3Props) => {
  if (
    item.children.length <= 1 ||
    item.children[1].type === TCustomCheckoutComponentType.none
  ) {
    return (
      <CheckoutComponenetWidget
        item={item.children[0]}
        id={id}
        loading={loading}
        loadingVoucher={loadingVoucher}
        isCheckoutBuilder={isCheckoutBuilder}
        searchedZipCode={searchedZipCode}
        data={data}
        control={control}
        errors={errors}
        productInstallments={productInstallments}
        installments={installments}
        orderBumps={orderBumps}
        products={products}
        paymentInstallment={paymentInstallment}
        paymentMethodType={paymentMethodType}
        paymentTotal={paymentTotal}
        paymentValue={paymentValue}
        watch={watch}
        setValue={setValue}
        onSubmit={onSubmit}
        onKeyDown={onKeyDown}
        saveLostSale={saveLostSale}
        clearErrors={clearErrors}
        searchZipCode={searchZipCode}
        handleAddresComplementKeyDown={handleAddresComplementKeyDown}
        handleAddresNumberKeyDown={handleAddresNumberKeyDown}
        orderBumpChange={orderBumpChange}
        setOpenTermsPurchase={setOpenTermsPurchase}
        onSendPixel={onSendPixel}
        onApplyVoucher={onApplyVoucher}
        onRemoveVoucher={onRemoveVoucher}
      />
    )
  }

  if (item.children[1].type === TCustomCheckoutComponentType.list) {
    const child = item.children[1].children.find(
      (cc) => cc.type !== TCustomCheckoutComponentType.none
    )
    if (!child) {
      return (
        <CheckoutComponenetWidget
          item={item.children[0]}
          id={id}
          loading={loading}
          loadingVoucher={loadingVoucher}
          isCheckoutBuilder={isCheckoutBuilder}
          searchedZipCode={searchedZipCode}
          data={data}
          control={control}
          errors={errors}
          productInstallments={productInstallments}
          installments={installments}
          orderBumps={orderBumps}
          products={products}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
          setValue={setValue}
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          orderBumpChange={orderBumpChange}
          setOpenTermsPurchase={setOpenTermsPurchase}
          onSendPixel={onSendPixel}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    }
  }

  return (
    <Grid container columns={3}>
      <Grid item sm={3} md={2}>
        {item.children.length > 0 && (
          <CheckoutComponenetWidget
            item={item.children[0]}
            id={id}
            loading={loading}
            loadingVoucher={loadingVoucher}
            isCheckoutBuilder={isCheckoutBuilder}
            searchedZipCode={searchedZipCode}
            data={data}
            control={control}
            errors={errors}
            productInstallments={productInstallments}
            installments={installments}
            orderBumps={orderBumps}
            products={products}
            paymentInstallment={paymentInstallment}
            paymentMethodType={paymentMethodType}
            paymentTotal={paymentTotal}
            paymentValue={paymentValue}
            watch={watch}
            setValue={setValue}
            onSubmit={onSubmit}
            onKeyDown={onKeyDown}
            saveLostSale={saveLostSale}
            clearErrors={clearErrors}
            searchZipCode={searchZipCode}
            handleAddresComplementKeyDown={handleAddresComplementKeyDown}
            handleAddresNumberKeyDown={handleAddresNumberKeyDown}
            orderBumpChange={orderBumpChange}
            setOpenTermsPurchase={setOpenTermsPurchase}
            onSendPixel={onSendPixel}
            onApplyVoucher={onApplyVoucher}
            onRemoveVoucher={onRemoveVoucher}
          />
        )}
      </Grid>
      <Grid item sm={3} md={1}>
        <Box
          sx={{
            width: "100%",
            pl: { xs: 0, sm: 2 },
            pt: { xs: 3, sm: 0 },
          }}
        >
          <Stack
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {item.children.length > 1 &&
              item.children[1].children.length <= 0 && (
                <Typography>Arraste um componente aqui...</Typography>
              )}
            <CheckoutComponenetWidget
              item={item.children[1]}
              id={id}
              loading={loading}
              loadingVoucher={loadingVoucher}
              isCheckoutBuilder={isCheckoutBuilder}
              searchedZipCode={searchedZipCode}
              data={data}
              control={control}
              errors={errors}
              productInstallments={productInstallments}
              installments={installments}
              orderBumps={orderBumps}
              products={products}
              paymentInstallment={paymentInstallment}
              paymentMethodType={paymentMethodType}
              paymentTotal={paymentTotal}
              paymentValue={paymentValue}
              watch={watch}
              setValue={setValue}
              onSubmit={onSubmit}
              onKeyDown={onKeyDown}
              saveLostSale={saveLostSale}
              clearErrors={clearErrors}
              searchZipCode={searchZipCode}
              handleAddresComplementKeyDown={handleAddresComplementKeyDown}
              handleAddresNumberKeyDown={handleAddresNumberKeyDown}
              orderBumpChange={orderBumpChange}
              setOpenTermsPurchase={setOpenTermsPurchase}
              onSendPixel={onSendPixel}
              onApplyVoucher={onApplyVoucher}
              onRemoveVoucher={onRemoveVoucher}
            />
          </Stack>
        </Box>
      </Grid>
    </Grid>
  )
}

export default CheckoutLayout2_3
