import { Box, Checkbox, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import { IOrderBump } from "../../../../../core/types/OrderBump";
import numbers from "../../../../../core/functions/NumberFunctions";
import React from "react";

export interface ICheckoutOrderBump3Prop {
    orderBump: IOrderBump;
    onChange(orderBump: IOrderBump): void;
}

const CheckoutOrderBump3 = ({ orderBump, onChange }: ICheckoutOrderBump3Prop): JSX.Element => {
    const handleClick = () => {
        orderBump.selected = !orderBump.selected;
        onChange(orderBump);
    }

    return (
        <ListItem
            key={`ob-${orderBump.id}`}
            divider
            disablePadding
            sx={{
                border: `2px solid ${red[700]}`,
                borderStyle: "dashed",
                borderRadius: 2,
                mb: 1,
                backgroundColor: "rgba(255, 255, 224, 0.5)",
            }}
        >
            <ListItemButton
                dense
                role={undefined}
                className="listItemButtonOrderBump"
                onClick={handleClick}
            >
                <Stack direction={{ xs: "column", sm: "row" }} spacing={1} sx={{ width: '100%', py: 1 }} alignItems="start">
                    <Stack direction="row">
                        <Checkbox checked={orderBump.selected ?? false} />
                        <Box sx={{ height: 100, width: 100 }}>
                            {orderBump.productPrice?.product?.coverUrl && orderBump.productPrice?.product?.coverUrl !== '' &&
                                <img
                                    src={!orderBump.productPrice?.product?.coverUrl ? `${process.env.PUBLIC_URL}/top.png` : orderBump.productPrice.product.coverUrl}
                                    alt={orderBump.productPrice?.product?.name}
                                    style={{ height: 100, width: 100, objectFit: 'cover', borderRadius: 5, }}
                                />
                            }
                        </Box>
                    </Stack>
                    <Stack direction={"column"} spacing={1} sx={{ flexGrow: 1 }}>
                        <Typography variant="body1" fontWeight={500} color={grey[800]}>
                            {orderBump.title || ''}
                        </Typography>
                        <Typography variant="body1" sx={{ flexGrow: 1 }}>
                            {orderBump.description || ''}
                        </Typography>
                        <Stack direction="row" spacing={1} sx={{ width: '100%' }} alignContent={"center"} alignItems={"center"}>
                            <Typography variant="body1">
                                {orderBump.descriptionPrice || ''} <strong style={{ color: red[700] }}>
                                    {` + ${numbers.formatMoneyDefault(orderBump.productPrice?.firstPurchasePrice ?? orderBump.productPrice?.value)}`}
                                </strong>
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </ListItemButton>
        </ListItem>
    );
}

export default CheckoutOrderBump3;