export enum CreditCardType {
    Visa,
    Master,
    Elo,
    Diners,
    Discover,
    JCB,
    American,
}

export interface CreditCard {
    getType(): CreditCardType;
    compare(number?: string): boolean;
    getImageUrl(): string;
}