import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../core/types/Product"
import { TSale, TSaleProduct } from "../../core/types/Sale"
import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutDetailsDefault from "./Layouts/LayoutDefault/CheckoutDetailsDefault"
import CheckoutDetails2 from "./Layouts/Layout2/CheckoutDetails2"
import React from "react"
import CheckoutDetails3 from "./Layouts/Layout3/CheckoutDetails3"
import CheckoutDetails4 from "./Layouts/Layout4/CheckoutDetails4"
import { UseFormWatch } from "react-hook-form"
import CheckoutDetails5 from "./Layouts/Layout5/CheckoutDetails5"

export interface ICheckoutDetails {
  layout?: TCheckoutLayout
  data: IProductPrice | null
  products: TSaleProduct[]
  paymentMethodType: number
  paymentInstallment: number
  productInstallments?: IProductPricePaymentInstallment[] | null
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
}

const CheckoutDetails = ({
  layout,
  data,
  products,
  paymentMethodType,
  paymentInstallment,
  productInstallments,
  paymentTotal,
  paymentValue,
  watch,
}: ICheckoutDetails) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutDetailsDefault
          data={data}
          products={products}
          paymentInstallment={paymentInstallment}
          productInstallments={productInstallments}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutDetails2
          data={data}
          products={products}
          paymentInstallment={paymentInstallment}
          productInstallments={productInstallments}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutDetails3
          data={data}
          products={products}
          paymentInstallment={paymentInstallment}
          productInstallments={productInstallments}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutDetails4
          data={data}
          products={products}
          paymentInstallment={paymentInstallment}
          productInstallments={productInstallments}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutDetails5
          data={data}
          products={products}
          paymentInstallment={paymentInstallment}
          productInstallments={productInstallments}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutDetails
