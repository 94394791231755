import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Theme,
  Typography,
  useTheme,
} from "@mui/material"
import React, { useContext, useState } from "react"
import ContentDialog from "../../../Dialogs/ContentDialog"
import { IProductPricePaymentInstallment } from "../../../../core/types/Product"
import numbers from "../../../../core/functions/NumberFunctions"
import CoverImageSecurity from "./CoverImageSecurity"
import CountryFunctions from "../../../../core/functions/CountryFunctions"
import { TCountry } from "../../../../core/types/Country"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"
import { CheckoutContext } from "../../../../core/contexts/CheckoutContext"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      borderRadius: "4px",
    },
  },
}

function getStyles(isSelected: boolean, theme: Theme) {
  return {
    fontWeight: !isSelected
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

export interface ICheckoutCoverImage4 {
  imageUrl?: string
  productImageUrl?: string
  productName: string
  productDescription: string
  productInstallments: IProductPricePaymentInstallment[] | undefined
  isCheckoutBuilder: boolean
  productValue: number
  offerName: string
}

const CheckoutCoverImage4 = ({
  imageUrl,
  productImageUrl,
  productName,
  productDescription,
  productInstallments,
  isCheckoutBuilder,
  productValue,
  offerName,
}: ICheckoutCoverImage4) => {
  const theme = useTheme()
  const { productPrice: data } = useContext(CheckoutContext)

  const [openDescription, setOpenDescription] = useState(false)
  const [country, setCountry] = useState<TCountry>({
    ...CountryFunctions.countryBrazil,
  })
  const [open, setOpen] = React.useState(false)

  const installmentNumbers = productInstallments?.map(
    (pi) => pi.installmentNumber
  ) ?? [0]
  const maxInstallment = Math.max(...installmentNumbers)
  const installmentFirst = productInstallments?.find(
    (pi) => pi.installmentNumber === 1
  )
  const installmentLast = productInstallments?.find(
    (pi) => pi.installmentNumber === maxInstallment
  )
  const value = installmentFirst?.value ?? productValue

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <Stack direction={"column"} spacing={0} width={"100%"}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
          sx={{ width: "100%", pt: 3, pb: 1, px: 3 }}
        >
          <FormControl sx={{ width: "209px" }} size="small">
            <Select
              labelId="select-country-label"
              id="select-country"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={country}
              onChange={(event) => {
                const c = CountryFunctions.countryValids.find(
                  (cv) => cv.code === event.target.value
                )
                if (c) {
                  setCountry(c)
                }
              }}
              renderValue={(selected) => (
                <Stack direction="row" spacing={2}>
                  <Typography>{selected.code}</Typography>
                  <Box
                    sx={{
                      backgroundColor: "#DCDDDE",
                      color: "white",
                      height: "22.5px",
                      px: "0.5px",
                    }}
                  />
                  <Typography>Alterar País</Typography>
                </Stack>
              )}
              MenuProps={MenuProps}
              IconComponent={() => (
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? (
                    <KeyboardArrowUpIcon color="disabled" />
                  ) : (
                    <KeyboardArrowDownIcon color="disabled" />
                  )}
                </IconButton>
              )}
              style={{ borderRadius: "8px" }}
            >
              {CountryFunctions.countryValids.map((countryValid) => (
                <MenuItem
                  key={countryValid.code}
                  value={countryValid.code}
                  style={getStyles(countryValid.code === country.code, theme)}
                >
                  <Stack direction="row" spacing={2}>
                    <Typography fontWeight={500}>
                      {countryValid.code}
                    </Typography>
                    <Typography sx={{ color: "#898493" }}>
                      {countryValid.name}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack direction="column" sx={{ pl: 3, pr: 3, pt: 3 }} width="100%">
          <Stack
            direction="row"
            spacing={2}
            alignContent="center"
            alignItems="center"
            width="100%"
          >
            <Box sx={{ height: 80, width: 80 }}>
              {productImageUrl && productImageUrl !== "" && (
                <img
                  src={productImageUrl}
                  alt={productName}
                  style={{
                    height: 80,
                    width: 80,
                    objectFit: "cover",
                    borderRadius: "8px",
                  }}
                />
              )}
            </Box>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={1}
              alignContent="space-between"
              justifyContent="space-between"
              width="100%"
            >
              <Box gap="16px">
                <Typography
                  color="#343948"
                  fontWeight={700}
                  sx={{ fontSize: 16, lineHeight: "20px" }}
                >
                  {productName}
                </Typography>
                <Typography
                  color="#343948"
                  fontWeight={500}
                  sx={{ fontSize: 14, lineHeight: "16px" }}
                >
                  {offerName}
                </Typography>
                <Typography
                  variant="caption"
                  color={{ xs: "#1C52BD", sm: "#707275" }}
                  fontWeight={400}
                  sx={{ fontSize: 12, lineHeight: "16px", cursor: "pointer" }}
                  onClick={() => setOpenDescription(true)}
                >
                  Detalhes do produto
                </Typography>
              </Box>
              <Stack
                direction={{ xs: "row", sm: "column" }}
                spacing={{ xs: 1, sm: 0 }}
                alignContent={{ xs: "center", sm: "end" }}
                alignItems={{ xs: "center", sm: "end" }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  alignContent="center"
                  alignItems="center"
                >
                  {(installmentLast?.installmentNumber ?? 0) > 1 && (
                    <Typography
                      color="#343948"
                      fontWeight={700}
                      sx={{ fontSize: 16, lineHeight: "20px" }}
                    >
                      {installmentLast?.installmentNumber}x
                    </Typography>
                  )}
                  <Typography
                    color="#343948"
                    fontWeight={700}
                    sx={{ fontSize: 16, lineHeight: "20px" }}
                  >
                    {numbers.formatMoneyDefault(
                      installmentLast?.value ?? value
                    )}
                  </Typography>
                </Stack>
                {(installmentLast?.installmentNumber ?? 0) > 1 && (
                  <Typography
                    color="#707275"
                    fontWeight={400}
                    sx={{ fontSize: 12, lineHeight: "16px" }}
                  >
                    ou {numbers.formatMoneyDefault(value)} à vista
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <CoverImageSecurity />
        <Stack sx={{ px: 3, mt: 3.5, mb: 0.5 }}>
          <Divider sx={{ color: "#DCDDDE" }} />
        </Stack>
      </Stack>
      <ContentDialog
        open={openDescription}
        title={productName}
        hasCancel={false}
        onClose={() => setOpenDescription(false)}
      >
        {productDescription}
      </ContentDialog>
    </>
  )
}

export default CheckoutCoverImage4
