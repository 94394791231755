import { Stack, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/CheckCircle";
import React from "react";

const BoletoPaymentPrice = ({ visible }: { visible: boolean }) => {
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{ display: visible ? "block" : "none" }}
    >
      <Stack direction="column">
        <Stack
          direction="row"
          spacing={1}
          alignContent={"center"}
          alignItems={"center"}
        >
          <CheckIcon color="primary" />
          <Typography variant="h6">Pague até a data do vencimento</Typography>
        </Stack>
        <Typography variant="body2">
          Faça o pagamento até a data de vencimento e garanta seu acesso ao
          produto.
        </Typography>
      </Stack>

      <Stack direction="column">
        <Stack
          direction="row"
          spacing={1}
          alignContent={"center"}
          alignItems={"center"}
        >
          <CheckIcon color="primary" />
          <Typography variant="h6">Aguarde a aprovação do pagamento</Typography>
        </Stack>
        <Typography variant="body2">
          Pagamentos com boleto levam <strong>até 3 dias</strong> para serem
          compensados.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default BoletoPaymentPrice;
