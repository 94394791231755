import {
  Box,
  FormControl,
  IconButton,
  InputBaseComponentProps,
  InputLabel,
  Select,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import { green, grey, red, teal } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Control, Controller } from "react-hook-form";
import { primaryColor } from "../../App";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #C5C6C9",
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "white",
  },
  select: {
    color: "#38393B",
    border: `2px solid #BCC1FF`,
    borderRadius: "4px",
    backgroundColor: "white",
    "&:before": {
      border: `2px solid ${red[900]}`,
    },
    "&:after": {
      borderColor: "#BCC1FF",
    },
  },
  icon: {
    fill: grey[300],
  },
}));

export interface IValidationTextFieldProp {
  control: Control<any, any>;
  fieldName: string;
  label: string;
  required?: boolean;
  children: string | JSX.Element | JSX.Element[];
  inputProps?: InputBaseComponentProps;
  backgroundColorTitle?: string | undefined;
  size?: "small";
  onFieldExit?: () => void;
  variant?: "default" | "labeled";
}

function SelectRequired({
  control,
  fieldName,
  label,
  required,
  children,
  onFieldExit,
  inputProps,
  backgroundColorTitle,
  size,
  variant = "default",
}: IValidationTextFieldProp) {
  const classes = useStyles();
  const { register } = control;

  const [open, setOpen] = React.useState(false);

  if (required === undefined || required === null) {
    required = true;
  }

  register(fieldName, { required });

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl fullWidth>
      {variant === "labeled" ? (
        <Typography
          sx={{ marginBottom: 1.5 }}
          fontSize="14px"
          fontWeight={500}
          lineHeight="18px"
          color="black"
        >
          {label}
        </Typography>
      ) : (
        <InputLabel
          id={fieldName}
          sx={{
            background: backgroundColorTitle
              ? `linear-gradient(${backgroundColorTitle}, white)`
              : "white",
            backgroundColor: "white",
            px: 1,
          }}
        >
          {label}
        </InputLabel>
      )}

      <Controller
        control={control}
        name={fieldName}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => (
          <Box>
            <Select
              fullWidth
              required={required}
              labelId={fieldName}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={value}
              defaultValue={""}
              error={error?.type === "required"}
              onBlur={() => {
                onBlur();
                if (onFieldExit) {
                  onFieldExit();
                }
              }}
              onChange={onChange}
              inputRef={ref}
              inputProps={inputProps}
              sx={{
                backgroundColor: "white",
                height: size === "small" ? "46px" : undefined,
                ".MuiOutlinedInput-notchedOutline": {
                  border: "2px solid #BCC1FF",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: `1px solid ${primaryColor}`,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #BCC1FF",
                },
                ".MuiSvgIcon-root ": {
                  fill: "#7E86A0 !important",
                },
              }}
              IconComponent={() => (
                <IconButton onClick={() => setOpen(!open)}>
                  {open ? (
                    <KeyboardArrowUpIcon color="info" />
                  ) : (
                    <KeyboardArrowDownIcon color="disabled" />
                  )}
                </IconButton>
              )}
            >
              {children}
            </Select>
            {invalid && (
              <Typography variant="overline" color={"red"}>
                Campo {`"${label}"`} obrigatório
              </Typography>
            )}
          </Box>
        )}
      />
    </FormControl>
  );
}

export default SelectRequired;
