import { List, Paper, Stack, Typography } from "@mui/material";
import { IOrderBump } from "../../../../core/types/OrderBump";
import React from "react";
import CheckoutOrderBump5 from "./OrderBump";

export interface ICheckoutOrderBumps5 {
  paymentInstallment: number;
  paymentMethodType: number;
  orderBumps?: IOrderBump[];
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>;
}

const CheckoutOrderBumps5 = ({
  paymentInstallment,
  paymentMethodType,
  orderBumps,
  orderBumpChange,
}: ICheckoutOrderBumps5) => {
  const isOne = (orderBumps?.length ?? 0) <= 1;
  const quantity = orderBumps?.length ?? 0;

  return (
    <Stack
      direction="column"
      spacing={1}
      sx={{
        pt: 2,
      }}
    >
      <Stack alignItems="center">
        <Typography
          color="#38393B"
          fontWeight={700}
          fontSize="24px"
          lineHeight="32px"
        >
          {`🎉 Você possui ${quantity} oferta${isOne ? "" : "s"}!`}
        </Typography>
        <Typography
          color="#707275"
          fontWeight={400}
          fontSize="14px"
          lineHeight="18px"
        >
          Oportunidade única de adquirir produtos incríveis com um super
          desconto!
        </Typography>
      </Stack>

      <List
        sx={{
          width: "100%",
          m: 0,
          p: "0px",
          margin: 0,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        {orderBumps &&
          orderBumps.map((orderBump, index) => (
            <CheckoutOrderBump5
              key={`CheckoutOrderBump3${orderBump.id}`}
              divider={index < orderBumps.length - 1}
              paymentInstallment={paymentInstallment}
              paymentMethodType={paymentMethodType}
              orderBump={orderBump}
              onChange={orderBumpChange}
            />
          ))}
      </List>
    </Stack>
  );
};

export default CheckoutOrderBumps5;
