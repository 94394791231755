const compare = (validates: string[], number?: string): boolean => {
    if (!number) {
        return false;
    }
    const type = validates.find((value) => number.trim().startsWith(value));
    if (type && type !== '') {
        return true;
    }
    return false;
}

const CreditCards = {
    compare,
}

export default CreditCards;