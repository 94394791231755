import { Container, Link, Stack, Typography } from "@mui/material";
import ContentDialog from "../../../components/Dialogs/ContentDialog";
import React from "react";

export interface ITermsPurchaseDialogProps {
    open: boolean;
    onClose: () => void;
}

const TermsPurchaseDialog = ({ open, onClose }: ITermsPurchaseDialogProps) => {
    return (
        <ContentDialog
            open={open}
            onClose={onClose}
            title="Termos de Compra"
            hasCancel={false}
            labelPrositive="OK"
            maxWidth={"md"}
        >
            <Container>
                <Stack direction="column" spacing={2}>
                    <iframe
                        src="https://celetus.com/termosecondicoes/"
                        title="Termos de uso"
                        height="400"
                        frameBorder={"0"}
                    />
                </Stack>
            </Container>
        </ContentDialog>
    );
};

export default TermsPurchaseDialog;
