import { Stack } from "@mui/material";

export interface ICheckoutHeaderProps {
    height?: string | number;
}

const CheckoutHeader = ({ height = 120 }: ICheckoutHeaderProps) => {
    return (
        <Stack
            alignContent="center"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: "100%",
                height: "100%",
            }}
        >
            Arraste um componente aqui...
        </Stack>
    );
}

export default CheckoutHeader;