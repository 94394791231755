import { Divider, Stack } from "@mui/material";
import ImagePlaceholder from "../../../Images/ImagePlaceholder";
import React from "react";
import { isWidget } from "../../../../core/constants";

export interface ICheckoutCoverImageDefault {
    imageUrl?: string;
}

const CheckoutCoverImageDefault = ({ imageUrl }: ICheckoutCoverImageDefault) => {
    if (isWidget() || !imageUrl || imageUrl === '') {
        return (<></>);
    }
    return (
        <Stack
            direction={"column"}
            spacing={2}
            width={"100%"}
        >
            <ImagePlaceholder
                imageUrl={imageUrl}
                alt="img-top"
                style={{
                    borderTopLeftRadius: 20,
                    borderTopRightRadius: 20,
                    objectFit: "contain",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%",
                }}
            />
            <Stack sx={{ px: 3 }}>
                <Divider />
            </Stack>
        </Stack>
    );
}

export default CheckoutCoverImageDefault;