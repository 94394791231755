import { Box, Typography, Stack, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { Control, FieldErrors, UseFormClearErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { TSale } from "../../../core/types/Sale";
import { creditsCards } from "../../../core/constants";
import { IProductPrice, IProductPricePaymentInstallment, TProductTypePayment } from "../../../core/types/Product";
import numbers from "../../../core/functions/NumberFunctions";
import { useEffect, useState } from "react";
import { CreditCard } from "../../../core/types/CreditCard";
import TextFieldRequired from "../../../components/Inputs/TextFieldRequired";
import SelectRequired from "../../../components/Inputs/SelectRequired";
import React from "react";

export interface ICreditCardPaymentPriceProps {
    visible: boolean;
    control: Control<TSale, any>;
    errors: FieldErrors<TSale>;
    data?: IProductPrice;
    watch: UseFormWatch<TSale>;
    setValue: UseFormSetValue<TSale>;
    installments: IProductPricePaymentInstallment[] | undefined;
    clearErrors: UseFormClearErrors<TSale>;
}

const CreditCardPaymentPrice = (props: ICreditCardPaymentPriceProps) => {
    const { visible, control, errors, data, watch, setValue, installments, clearErrors } = props;
    const { register } = control;

    const [creditCard, setCreditCard] = useState<CreditCard | undefined>();

    const paymentCardNumber = watch("payment.cardNumber");
    const paymentCardCVV = watch("payment.cardCCV");

    const list = installments?.sort((a, b) => (a.installmentNumber > b.installmentNumber ? -1 : 1));
    const itensMenu =
        list?.map((x) => ({
            key: x.installmentNumber,
            label: `${x.installmentNumber} de ${numbers.formatMoneyDefault(x.value)}`,
        })) || [];

    useEffect(() => {
        var exists = false;
        creditsCards.map((cc) => {
            if (cc.compare(paymentCardNumber)) {
                setCreditCard(cc);
                exists = true;
            }
            return true;
        });
        if (!exists) {
            setCreditCard(undefined);
        }
    }, [paymentCardNumber]);

    useEffect(() => {
        if ((paymentCardCVV || "").length === 4) {
            // comentado pois foi solicitado a alteração para abrir automático as parcelas
            // depois foi pedido para remover, caso seja necessário voltar descomentar
            //handleOpenInstallment();
            document.getElementById("buttonCommit")?.focus();
        }
    }, [paymentCardCVV]);

    return (
        <Stack direction="column" spacing={2} sx={{ display: visible ? "block" : "none" }}>
            <TextFieldRequired
                control={control}
                fieldName="payment.cardName"
                label="Nome Impresso no Cartão"
                placeholder="Digite o nome impresso no cartão"
                clearErrors={clearErrors}
                required={visible}
                inputProps={{
                    "data-cy": "cardName",
                    "data-testid": "cardName",
                }}
                dataCyErrorLabel="cardNameError"
            />
            <TextFieldRequired
                control={control}
                fieldName="payment.cardNumber"
                label="Número do Cartão"
                placeholder="Digite somente números"
                inputProps={{
                    inputMode: "numeric",
                    maxLength: 19,
                    "data-cy": "cardNumber",
                    "data-testid": "cardNumber",
                }}
                dataCyErrorLabel="cardNumberError"
                clearErrors={clearErrors}
                required={visible}
                endAdornment={
                    creditCard && (
                        <img
                            src={creditCard.getImageUrl()}
                            alt="credit card"
                            style={{ height: 30, objectFit: "contain" }}
                        />
                    )
                }
            />
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ width: "100%" }}>
                <TextFieldRequired
                    control={control}
                    fieldName="payment.cardValidate"
                    label="Validade do Cartão (MM/AA)"
                    placeholder="MM/AA"
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 5,
                        "data-cy": "cardValidate",
                        "data-testid": "cardValidate",
                    }}
                    dataCyErrorLabel="cardValidateError"
                    clearErrors={clearErrors}
                    required={visible}
                />
                <TextFieldRequired
                    control={control}
                    fieldName="payment.cardCCV"
                    label="Código de Segurança (CCV)"
                    placeholder="999"
                    inputProps={{
                        inputMode: "numeric",
                        maxLength: 4,
                        "data-cy": "cardCCV",
                        "data-testid": "cardCCV",
                    }}
                    dataCyErrorLabel="cardCCVError"
                    clearErrors={clearErrors}
                    required={visible}
                />
            </Stack>
            <SelectRequired
                control={control}
                fieldName="payment.installment"
                label="Número de parcelas"
                required={visible}
            >
                {itensMenu.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.label}
                    </MenuItem>
                ))}
            </SelectRequired>
        </Stack>
    );
};

export default CreditCardPaymentPrice;
