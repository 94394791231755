import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import GppGoodIcon from "@mui/icons-material/GppGood";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import LockIcon from "@mui/icons-material/Lock";
import ImagePlaceholder from "../../../Images/ImagePlaceholder";
import React, { useState } from "react";
import { isWidget } from "../../../../core/constants";
import { grey } from "@mui/material/colors";
import ContentDialog from "../../../Dialogs/ContentDialog";
import { IProductPricePaymentInstallment } from "../../../../core/types/Product";
import numbers from "../../../../core/functions/NumberFunctions";

export interface ICheckoutCoverImage3 {
    imageUrl?: string;
    productImageUrl?: string;
    productName: string;
    productDescription: string;
    productInstallments: IProductPricePaymentInstallment[] | undefined;
    isCheckoutBuilder: boolean;
    productValue: number;
}

const CheckoutCoverImage3 = ({
    imageUrl,
    productImageUrl,
    productName,
    productDescription,
    productInstallments,
    isCheckoutBuilder,
    productValue,
}: ICheckoutCoverImage3) => {
    const [openDescription, setOpenDescription] = useState(false);

    let installment: IProductPricePaymentInstallment | undefined;
    let value = productInstallments?.find((pi) => pi.installmentNumber === 1)?.total ?? productValue;

    productInstallments?.map((productInstallment) => {
        if (!installment || installment.installmentNumber < productInstallment.installmentNumber) {
            installment = { ...productInstallment };
        }
    });

    return (
        <>
            <Stack direction={"column"} spacing={0} width={"100%"}>
                {!isWidget() && !isCheckoutBuilder && imageUrl && imageUrl !== "" && (
                    <ImagePlaceholder
                        imageUrl={imageUrl}
                        alt="img-top"
                        style={{
                            borderTopLeftRadius: "8px",
                            borderTopRightRadius: "8px",
                            objectFit: "contain",
                            objectPosition: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                )}
                <Stack direction="column" sx={{ pl: { sm: 6, xs: 4 }, pr: 2, pt: 2 }} width="100%">
                    <Stack direction="row" spacing={2} width="100%">
                        <Box sx={{ height: 100, width: 100 }}>
                            {productImageUrl && productImageUrl !== "" && (
                                <img
                                    src={productImageUrl}
                                    alt={productName}
                                    style={{ height: 100, width: 100, objectFit: "cover", borderRadius: "8px" }}
                                />
                            )}
                        </Box>
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={1}
                            alignContent="space-between"
                            justifyContent="space-between"
                            width="100%"
                        >
                            <Box>
                                <Typography color={grey[800]} sx={{ fontSize: 18 }}>
                                    {productName}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    color={grey[700]}
                                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                                    onClick={() => setOpenDescription(true)}
                                >
                                    Detalhes do produto
                                </Typography>
                            </Box>
                            <Stack direction="column" alignContent="end" alignItems="end">
                                <Stack direction="row" spacing={1} alignContent="center" alignItems="center">
                                    {(installment?.installmentNumber ?? 0) > 1 && (
                                        <Typography fontWeight={500} color={grey[800]} sx={{ fontSize: 12 }}>
                                            {installment?.installmentNumber}x
                                        </Typography>
                                    )}
                                    <Typography fontWeight={500} color={grey[800]} sx={{ fontSize: 16 }}>
                                        {numbers.formatMoneyDefault(installment?.value ?? value)}
                                    </Typography>
                                </Stack>
                                {(installment?.installmentNumber ?? 0) > 1 && (
                                    <Typography color={grey[800]} sx={{ fontSize: 14 }}>
                                        ou {numbers.formatMoneyDefault(value)} à vista
                                    </Typography>
                                )}
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Divider sx={{ mt: { sm: 3, xs: 1 }, mx: { sm: 3, xs: 1 } }} />
                <Box display={{ sm: "block", xs: "none" }} sx={{ pt: 2 }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ px: 3 }}>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <GppGoodIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                                <Typography>Compra Segura</Typography>
                            </Stack>
                        </Card>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <EmojiEventsIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                                <Typography>Satisfação Garantida</Typography>
                            </Stack>
                        </Card>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <LockIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                                <Typography>Privacidade Protegida</Typography>
                            </Stack>
                        </Card>
                    </Stack>
                </Box>
                <Box display={{ sm: "none", xs: "block" }} sx={{ pt: 2 }}>
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ px: 1 }}>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <GppGoodIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                                <Typography variant="caption">Compra Segura</Typography>
                            </Stack>
                        </Card>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <EmojiEventsIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                                <Typography variant="caption">Satisfação Garantida</Typography>
                            </Stack>
                        </Card>
                        <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                            <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                                <LockIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                                <Typography variant="caption">Privacidade Protegida</Typography>
                            </Stack>
                        </Card>
                    </Stack>
                </Box>
                <Stack sx={{ px: { sm: 3, xs: 1 }, mt: 2 }}>
                    <Divider />
                </Stack>
            </Stack>
            <ContentDialog
                open={openDescription}
                title={productName}
                hasCancel={false}
                onClose={() => setOpenDescription(false)}
            >
                {productDescription}
            </ContentDialog>
        </>
    );
};

export default CheckoutCoverImage3;
