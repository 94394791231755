import { Box, List, Stack, Typography } from "@mui/material";
import { IOrderBump } from "../../../../core/types/OrderBump";
import React from "react";
import { blueGrey, grey } from "@mui/material/colors";
import CheckoutOrderBump3 from "./OrderBump";

export interface ICheckoutOrderBumps3 {
    orderBumps?: IOrderBump[];
    orderBumpChange: (orderBump: IOrderBump) => Promise<void>;
}

const CheckoutOrderBumps3 = ({ orderBumps, orderBumpChange }: ICheckoutOrderBumps3) => {
    return (
        <Stack
            direction="column"
            spacing={3}
            sx={{
                border: 1,
                borderColor: grey[300],
                borderRadius: 2,
                backgroundColor: blueGrey[50],
                px: { xs: 1, sm: 3 },
                py: 3,
            }}
        >
            <Box sx={{ px: 5 }}>
                <Typography
                    color={grey[800]}
                    fontWeight={500}
                    sx={{ fontSize: 18 }}
                >
                    Oferta especial:
                </Typography>
                <Typography
                    color={grey[700]}
                    sx={{ fontSize: 14 }}
                >
                    APROVEITE! 93% das pessoas que compraram esse curso se interessam por estes produtos e levaram eles junto!
                </Typography>
            </Box>
            <List sx={{ width: "100%" }}>
                {orderBumps &&
                    orderBumps.map((orderBump) => (
                        <CheckoutOrderBump3
                            key={`CheckoutOrderBump3${orderBump.id}`}
                            orderBump={orderBump}
                            onChange={orderBumpChange}
                        />
                    ))}
            </List>
        </Stack>
    );
}

export default CheckoutOrderBumps3;