import {
  Control,
  FieldErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import {
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentType,
} from "../../core/types/CustomCheckout"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../core/types/Product"
import StopWatchWidget from "../Checkout/StopWatch"
import CheckoutComponenetList from "./CheckoutComponentList"
import CheckoutHeader from "./CheckoutHeader"
import CheckoutImage from "./CheckoutImage"
import CheckoutLayout2_3 from "./CheckoutLayout2_3"
import CheckoutText from "./CheckoutText"
import CheckoutWidget from "./CheckoutWidget"
import { TSale, TSaleProduct } from "../../core/types/Sale"
import { IOrderBump } from "../../core/types/OrderBump"

export interface ICheckoutComponenetWidgetProps {
  item: TCustomCheckoutComponentItem
  id: React.MutableRefObject<string>
  loading: boolean
  loadingVoucher: boolean
  isCheckoutBuilder: boolean
  searchedZipCode: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  errors: FieldErrors<TSale>
  productInstallments?: IProductPricePaymentInstallment[]
  installments?: IProductPricePaymentInstallment[]
  orderBumps?: IOrderBump[]
  products: TSaleProduct[]
  paymentInstallment: number
  paymentMethodType: number
  paymentTotal: number
  paymentValue: number
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  onKeyDown?: React.KeyboardEventHandler<HTMLFormElement> | undefined
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>
  setOpenTermsPurchase: (value: boolean) => void
  onSendPixel: () => void
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

const CheckoutComponenetWidget = ({
  item,
  id,
  loading,
  loadingVoucher,
  isCheckoutBuilder,
  searchedZipCode,
  data,
  control,
  errors,
  productInstallments,
  installments,
  orderBumps,
  products,
  paymentInstallment,
  paymentMethodType,
  paymentTotal,
  paymentValue,
  watch,
  setValue,
  onSubmit,
  onKeyDown,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresComplementKeyDown,
  handleAddresNumberKeyDown,
  orderBumpChange,
  setOpenTermsPurchase,
  onSendPixel,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutComponenetWidgetProps) => {
  switch (item.type) {
    case TCustomCheckoutComponentType.checkout:
      return (
        <CheckoutWidget
          id={id}
          loading={loading}
          loadingVoucher={loadingVoucher}
          isCheckoutBuilder={isCheckoutBuilder}
          searchedZipCode={searchedZipCode}
          data={data}
          control={control}
          errors={errors}
          productInstallments={productInstallments}
          installments={installments}
          orderBumps={orderBumps}
          products={products}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
          setValue={setValue}
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          orderBumpChange={orderBumpChange}
          setOpenTermsPurchase={setOpenTermsPurchase}
          onSendPixel={onSendPixel}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    case TCustomCheckoutComponentType.stopWatch:
      return (
        <StopWatchWidget
          initialTime={item.milleseconds ?? 900000}
          text={item.title}
          textFinished={item.subtitle}
          backgroundColor={item.sx.backgroundColor}
          color={item.sx.color}
          isFixedTop={item.fixedTop}
        />
      )
    case TCustomCheckoutComponentType.layout2_3:
      return (
        <CheckoutLayout2_3
          item={item}
          id={id}
          loading={loading}
          loadingVoucher={loadingVoucher}
          isCheckoutBuilder={isCheckoutBuilder}
          searchedZipCode={searchedZipCode}
          data={data}
          control={control}
          errors={errors}
          productInstallments={productInstallments}
          installments={installments}
          orderBumps={orderBumps}
          products={products}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
          setValue={setValue}
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          orderBumpChange={orderBumpChange}
          setOpenTermsPurchase={setOpenTermsPurchase}
          onSendPixel={onSendPixel}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    case TCustomCheckoutComponentType.list:
      return (
        <CheckoutComponenetList
          key={`CheckoutComponenetList${item.id}`}
          list={item.children}
          id={id}
          loading={loading}
          loadingVoucher={loadingVoucher}
          isCheckoutBuilder={isCheckoutBuilder}
          searchedZipCode={searchedZipCode}
          data={data}
          control={control}
          errors={errors}
          productInstallments={productInstallments}
          installments={installments}
          orderBumps={orderBumps}
          products={products}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          paymentTotal={paymentTotal}
          paymentValue={paymentValue}
          watch={watch}
          setValue={setValue}
          onSubmit={onSubmit}
          onKeyDown={onKeyDown}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          orderBumpChange={orderBumpChange}
          setOpenTermsPurchase={setOpenTermsPurchase}
          onSendPixel={onSendPixel}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    case TCustomCheckoutComponentType.text:
      return <CheckoutText text={item.title} />
    case TCustomCheckoutComponentType.image:
      return (
        <CheckoutImage
          imageUrl={item.imageUrl}
          alt={item.imageName ?? item.id}
        />
      )
    default:
      return <CheckoutHeader height={item.sx.height} />
  }
}

export default CheckoutComponenetWidget
