import { Box, Button, Stack, Typography } from "@mui/material";

const CheckoutButtonCommit5 = () => {
  return (
    <Stack
      direction={"column"}
      spacing={2}
      alignContent={"center"}
      alignItems={"center"}
      sx={{ width: "100%", pt: 1 }}
    >
      <Button
        fullWidth
        id="buttonCommit"
        type="submit"
        variant="contained"
        style={{
          height: "56px",
          textTransform: "none",
          border: "0px solid black",
          color: "white",
          backgroundColor: "#1C52BD",
        }}
        sx={{ fontWeight: 700, fontSize: "14px", lineHeight: "24px" }}
        data-testid="buttonCommit"
      >
        <Typography fontWeight={500}>Pagar e Receber Agora</Typography>
      </Button>
      <Typography fontSize="14px" lineHeight="18px" color="#707275">
        Ambiente criptografado e 100% seguro
      </Typography>
    </Stack>
  );
};

export default CheckoutButtonCommit5;
