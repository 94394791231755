import { Paper, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import CheckoutTextView from "./CheckoutTextView";

export interface ICheckoutHeaderProps {
    text: string;
}

const CheckoutText = ({ text }: ICheckoutHeaderProps) => {
    return (
        <Paper
            square={true}
            elevation={0}
            sx={{
                width: "100%",
                borderRadius: "8px",
                backgroundColor: "white",
                color: grey[800],
                py: 2,
            }}
        >
            <Stack
                direction="column"
                sx={{
                    width: "100%",
                    height: "100%",
                }}
            >
                <CheckoutTextView initialContent={text} />
            </Stack>
        </Paper>
    );
};

export default CheckoutText;
