import { Box, Card, Divider, Stack, Typography } from "@mui/material"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
  TProductTypePayment,
} from "../../../../core/types/Product"
import { TSaleProduct } from "../../../../core/types/Sale"
import OrderItem from "../../../../pages/Checkout/OrderProduct/OrderItem"
import OrderProduct from "../../../../pages/Checkout/OrderProduct"
import { methodTypeCreditCard } from "../../../../core/constants"
import LockIcon from "@mui/icons-material/Lock"
import React from "react"

export interface ICheckoutDetails3 {
  data: IProductPrice | null
  products: TSaleProduct[]
  paymentMethodType: number
  paymentInstallment: number
  productInstallments?: IProductPricePaymentInstallment[] | null
  paymentTotal: number
  paymentValue: number
}

const CheckoutDetails3 = ({
  data,
  products,
  paymentMethodType,
  paymentInstallment,
  productInstallments,
  paymentTotal,
  paymentValue,
}: ICheckoutDetails3) => {
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          elevation={0}
          sx={{
            backgroundColor: "#f5f5f5",
            p: 1,
            width: { sm: "60%", xs: "90%" },
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <LockIcon color="primary" sx={{ fontSize: "2.2rem" }} />
            <Typography variant="h6">Ambiente 100% seguro</Typography>
          </Stack>
          <Typography sx={{ p: 1 }}>
            Processamos os pagamentos com <strong>certificado SSL</strong>{" "}
            máxima segurança e privacidade na sua compra.
          </Typography>
        </Card>
      </Stack>

      <Typography variant="h6" gutterBottom>
        Detalhes da compra
      </Typography>

      {((data?.typePayment === TProductTypePayment.RecurringSubscription &&
        (data?.firstPurchasePrice || 0) > 0) ||
        products.find(
          (p) =>
            p.typePayment === TProductTypePayment.RecurringSubscription &&
            (p.firstPurchasePrice || 0) > 0
        )) && (
        <Box>
          <Typography variant="body1" fontWeight={500} gutterBottom>
            Primeira parcela
          </Typography>
          <Divider />
        </Box>
      )}

      {data?.typePayment === TProductTypePayment.RecurringSubscription &&
        (data?.firstPurchasePrice || 0) > 0 && (
          <OrderItem
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            description={data?.productName || ""}
            value={data?.firstPurchasePrice || data?.value || 0}
            defaultValue={0}
            installments={productInstallments}
            typePayment={data?.typePayment}
            frequency={data?.frequency}
          />
        )}

      {products
        .filter(
          (p) =>
            p.typePayment === TProductTypePayment.RecurringSubscription &&
            (p.firstPurchasePrice || 0) > 0
        )
        .map((product) => (
          <OrderProduct
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {paymentMethodType === methodTypeCreditCard &&
        ((data?.firstPurchasePrice || 0) <= 0 ||
          products.filter((p) => (p.firstPurchasePrice || 0) <= 0).length >
            0) && (
          <Box>
            <Typography variant="body1" fontWeight={500} gutterBottom>
              Parcelas
            </Typography>
            <Divider />
          </Box>
        )}

      {(data?.firstPurchasePrice || 0) <= 0 && (
        <OrderItem
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description={data?.productName || ""}
          value={data?.firstPurchasePrice || data?.value || 0}
          defaultValue={0}
          installments={productInstallments}
          typePayment={data?.typePayment}
          frequency={data?.frequency}
        />
      )}

      {products
        .filter((p) => (p.firstPurchasePrice || 0) <= 0)
        .map((product) => (
          <OrderProduct
            paymentMethodType={paymentMethodType}
            installmentNumber={paymentInstallment}
            product={product}
          />
        ))}

      {products.length > 0 && <Divider />}
      {products.length > 0 && (
        <OrderItem
          subtitle
          paymentMethodType={paymentMethodType}
          installmentNumber={paymentInstallment}
          description="Total"
          value={paymentTotal}
          defaultValue={paymentValue}
          installments={null}
          typePayment={data?.typePayment}
        />
      )}
    </Stack>
  )
}

export default CheckoutDetails3
