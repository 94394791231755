import { Box, Card, Divider, Stack, Typography } from "@mui/material";
import GppGoodIcon from '@mui/icons-material/GppGood';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import LockIcon from '@mui/icons-material/Lock';
import ImagePlaceholder from "../../../Images/ImagePlaceholder";
import React from "react";
import { isWidget } from "../../../../core/constants";

export interface ICheckoutCoverImageDefault {
    imageUrl?: string;
}

const CheckoutCoverImageDefault = ({ imageUrl }: ICheckoutCoverImageDefault) => {
    return (
        <Stack
            direction={"column"}
            spacing={2}
            width={"100%"}
        >
            {!isWidget() && imageUrl && imageUrl !== '' && (
                <ImagePlaceholder
                    imageUrl={imageUrl}
                    alt="img-top"
                    style={{
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20,
                        objectFit: "contain",
                        objectPosition: "center",
                        width: "100%",
                        height: "100%",
                    }}
                />
            )}
            <Box display={{ sm: "block", xs: "none" }} sx={{ pt: !isWidget() && imageUrl && imageUrl !== '' ? 0 : 2 }}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ px: 3 }}>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <GppGoodIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                            <Typography>Compra Segura</Typography>
                        </Stack>
                    </Card>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <EmojiEventsIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                            <Typography>Satisfação Garantida</Typography>
                        </Stack>
                    </Card>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <LockIcon color="primary" sx={{ fontSize: "2.2rem" }} />
                            <Typography>Privacidade Protegida</Typography>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
            <Box display={{ sm: "none", xs: "block" }} sx={{ pt: !isWidget() && imageUrl && imageUrl !== '' ? 0 : 2 }}>
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" sx={{ px: 1 }}>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <GppGoodIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                            <Typography variant="caption">Compra Segura</Typography>
                        </Stack>
                    </Card>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <EmojiEventsIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                            <Typography variant="caption">Satisfação Garantida</Typography>
                        </Stack>
                    </Card>
                    <Card elevation={0} sx={{ backgroundColor: "#f5f5f5" }}>
                        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
                            <LockIcon color="primary" sx={{ fontSize: "1.5rem" }} />
                            <Typography variant="caption">Privacidade Protegida</Typography>
                        </Stack>
                    </Card>
                </Stack>
            </Box>
            <Stack sx={{ px: { sm: 3, xs: 1 } }}>
                <Divider />
            </Stack>
        </Stack>
    );
}

export default CheckoutCoverImageDefault;