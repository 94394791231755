import { createContext, ReactNode, useEffect, useState } from "react"
import { TCustomCheckoutComponentItemSX } from "../types/CustomCheckout"
import {
  TCheckoutContext,
  TCheckoutContextItemType,
  TCheckoutInstallmentsContext,
  TPixelContext,
} from "../types/Checkout"
import { IProductPrice } from "../types/Product"

type Props = {
  children?: ReactNode
}

const initialPixelValue = {
  meta: [],
}

const initialValue = {
  checkoutSettings: null,
  setCheckoutSettings: (value: TCustomCheckoutComponentItemSX | null) => {},
  productPrice: null,
  setProductPrice: (value: IProductPrice | null) => {},
  installment: undefined,
  setInstallment: (value: TCheckoutInstallmentsContext | undefined) => {},
  pixel: initialPixelValue,
  setPixel: (value: TPixelContext) => {},
  isFacebookPixelSent: (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => false,
  addFacebookPixelSent: (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => {},
}

const CheckoutContext = createContext<TCheckoutContext>(initialValue)

const CheckoutProvider = ({ children }: Props) => {
  const [checkoutSettings, setCheckoutSettings] =
    useState<TCustomCheckoutComponentItemSX | null>(null)
  const [productPrice, setProductPrice] = useState<IProductPrice | null>(null)
  const [installment, setInstallment] = useState<
    TCheckoutInstallmentsContext | undefined
  >(undefined)

  const [pixel, setPixel] = useState<TPixelContext>(() => {
    const savedValue = localStorage.getItem("cepi")
    return savedValue ? JSON.parse(savedValue) : initialPixelValue
  })

  useEffect(() => {
    if (pixel !== null) {
      localStorage.setItem("cepi", JSON.stringify(pixel))
    }
  }, [pixel])

  const isFacebookPixelSent = (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => {
    const item = pixel.meta.find((p) => p.codeId === codeId && p.type === type)
    return item !== undefined && item !== null
  }

  const addFacebookPixelSent = (
    codeId: string | null | undefined,
    type: TCheckoutContextItemType
  ) => {
    if (isFacebookPixelSent(codeId, type)) return
    if (codeId) {
      pixel.meta.push({ codeId, type })
      setPixel({ ...pixel })
    }
  }

  return (
    <CheckoutContext.Provider
      value={{
        checkoutSettings,
        setCheckoutSettings,
        productPrice,
        setProductPrice,
        installment,
        setInstallment,
        pixel,
        setPixel,
        isFacebookPixelSent,
        addFacebookPixelSent,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  )
}

export { CheckoutContext, CheckoutProvider }
