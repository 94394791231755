import { Button, Stack, Typography } from "@mui/material";

const CheckoutButtonCommit2 = () => {
    return (
        <Stack
            direction={"column"}
            alignContent={"center"}
            alignItems={"center"}
            sx={{ width: "100%", pt: 2 }}
        >
            <Button
                id="buttonCommit"
                type="submit"
                variant="contained"
                sx={{ pl: 5, pt: 2, pr: 5, pb: 2 }}
            >
                <Typography variant="h6">FINALIZAR COMPRA</Typography>
            </Button>
        </Stack>
    );
}

export default CheckoutButtonCommit2;