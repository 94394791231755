import { Avatar, Box, Card, Stack } from "@mui/material"

export interface CoverImageSecurityItemProps {
  imgSrc: string
  children: JSX.Element | JSX.Element[]
}

const CoverImageSecurityItem5 = ({
  imgSrc,
  children,
}: CoverImageSecurityItemProps) => {
  return (
    <Card
      elevation={0}
      sx={{
        width: "100%",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={1}
        alignItems="start"
        justifyContent="start"
        sx={{ p: { xs: "10px", sm: "12px" }, gap: { xs: "2px", sm: "12px" } }}
      >
        <Avatar sx={{ bgcolor: "#F2F2FF" }}>
          <img src={imgSrc} alt="" />
        </Avatar>
        <Stack width="100%" alignItems="start" justifyContent="center">
          {children}
        </Stack>
      </Stack>
    </Card>
  )
}

export default CoverImageSecurityItem5
