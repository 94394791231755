import { TPhoneCode } from "../types/Phone";

const format = (value?: number, fractional?: number, prefixMoney?: boolean, def?: number): string => {
    if (value === undefined || value === null) {
        value = def;
    }

    if (value === undefined || value === null) {
        return '';
    }

    var money = "";

    if (prefixMoney) {
        money = value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
        return money;
    }

    money = value.toLocaleString('pt-br', { minimumFractionDigits: fractional });
    return money;
}

const formatMoney = (value?: number, prefixMoney?: boolean, def?: number) => {
    return format(value, 2, prefixMoney, def);
}

const formatMoneyDefault = (value?: any) => {
    const number = toNumber(value);
    return formatMoney(number, true, 0);
}

const toNumber = (value: any) => {
    if ((typeof value) === 'string') {
        value = String(value).replace(",", ".");
        return Number(value);
    }
    return value;
}

const toPercentage = (value?: any) => {
    value = toNumber(value || 0);
    const percentage = Number(value).toString().replace(",", "").replace(".", ",");
    return `${percentage}%`;
}

const getRandomInt = (max: number) => {
    return Math.floor(Math.random() * max);
}

const round = (value: number, fractional?: number) => {
    fractional ??= 2;
    return Number(value.toFixed(fractional));
}

// https://codesandbox.io/p/sandbox/mui-phone-input-g7590?file=%2Fsrc%2Fdata.json%3A2%2C23-2%2C36
const phoneCodeBR: TPhoneCode = {
    // image: https://purecatamphetamine.github.io/country-flag-icons/1x1/BR.svg
    flag: "/assets/icons/countries/1x1/BR.svg",
    code: "+55",
    countryName: "Brasil",
    countryCode: "BR",
}

const phoneCodeUS: TPhoneCode = {
    // image: https://purecatamphetamine.github.io/country-flag-icons/1x1/US.svg
    flag: "/assets/icons/countries/1x1/US.svg",
    code: "+123",
    countryName: "Estados Unidos",
    countryCode: "US",
}

const phoneCodes = [
    phoneCodeBR,
];

const NumberFunctions = {
    format,
    formatMoney,
    formatMoneyDefault,
    toNumber,
    toPercentage,
    getRandomInt,
    round,
    phoneCodeBR,
    phoneCodes,
}

export default NumberFunctions;