import api, { getResponseError } from "../../services/api";
import { IErrorResponse, IResponse } from "../types/ErrorResponse";
import { TLostSale, TLostSalePagination } from "../types/LostSale";

interface IGetProp {
    page: number;
    rows: number;
    search?: string;
}

const getAll = async ({ page, rows, search }: IGetProp): Promise<TLostSalePagination | IErrorResponse> => {
    try {
        var path = `/LostSale?PageNumber=${page}&RowsPerPage=${rows}`;

        if (search && search !== '') {
            path += `&Search=${search}`;
        }

        const { data } = await api.get<TLostSalePagination>(path);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const get = async ({ id }: { id: string }): Promise<TLostSale | IErrorResponse> => {
    try {
        const { data } = await api.get<TLostSale>(`/LostSale/${id}`);
        if (data) {
            return data;
        }

        return {
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            code: 2,
            error: e,
        };
    }
}

const insert = async ({ data }: { data: TLostSale }): Promise<IResponse> => {
    try {
        const { data: response } = await api.post('/LostSale', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const update = async ({ data }: { data: TLostSale }): Promise<IResponse> => {
    try {
        const { data: response } = await api.put('/LostSale', data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const LostSaleController = {
    getAll,
    get,
    insert,
    update,
}

export default LostSaleController;