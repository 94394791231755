import { Container, Paper, Stack, Typography } from "@mui/material"
import SuccessIcon from "@mui/icons-material/CheckCircle"
import { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useLocalStorage } from "../../../core/hooks/useLocalStorage"
import TagFunctions, { ITagsProp } from "../../../core/functions/TagFunctions"
import { TSaleStorage } from "../../../core/types/Sale"
import Hosts from "../../../core/functions/HostFunctions"
import React from "react"
import MetaFunctions from "../../../core/functions/MetaFunctions"
import { grey } from "@mui/material/colors"
import CheckoutFunctions from "../../../core/functions/CheckoutFunctions"
import { useQuery } from "../../../components/Query/useQuery"
import GoogleFunctions from "../../../core/functions/GoogleFunctions"
import {
  methodTypeCreditCard,
  methodTypeDebitCard,
} from "../../../core/constants"
import { useCheckoutContext } from "../../../core/hooks/useCheckoutContext"

const CheckoutSuccessPage = (): JSX.Element => {
  const { codeId } = useParams<{ codeId?: string }>()

  const query = useQuery()
  const { isFacebookPixelSent, addFacebookPixelSent } = useCheckoutContext()

  const googleAds = useRef<ITagsProp[] | null>(null)
  const tagInitialDataLayer = useRef<ITagsProp | null>(null)
  const tagDataLayer = useRef<ITagsProp | null>(null)
  const tagGTM = useRef<ITagsProp | null>(null)

  const [saleStorage] = useLocalStorage("afpa:sale", null)

  const [sale, setSale] = useState<TSaleStorage | undefined>(undefined)
  const [redirectSeconds, setRedirectSeconds] = useState(3)
  const [isRedirect, setIsRedirect] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (saleStorage === null) {
      Hosts.openNavigate(`/${codeId}`, navigate)
      return
    }
    setSale(saleStorage)

    if (!tagInitialDataLayer.current) {
      tagInitialDataLayer.current = GoogleFunctions.initialDataLayer()
    }

    return () => {
      if (tagInitialDataLayer.current) {
        TagFunctions.removeTags(tagInitialDataLayer.current)
      }
    }
  }, [])

  useEffect(() => {
    if (sale) {
      if (!isFacebookPixelSent(sale.orderCode, "purchase")) {
        MetaFunctions.addTagPurchase(sale)
        MetaFunctions.sendPurchaseAPI(sale)
        addFacebookPixelSent(sale.orderCode, "purchase")
      }

      if (
        [methodTypeCreditCard, methodTypeDebitCard].includes(sale.paymentType)
      ) {
        if (!googleAds.current) {
          googleAds.current = GoogleFunctions.addTagGoogleAdsPixelPurchase(sale)
        }

        if (!tagDataLayer.current) {
          tagDataLayer.current = GoogleFunctions.addDataLayerPurchase(sale)
        }
        if (!tagGTM.current) {
          tagGTM.current = GoogleFunctions.addGoogleTagManager(
            sale.googleTagManagerId
          )
        }
      }
    }
    return () => {
      if (tagDataLayer.current) {
        TagFunctions.removeTags(tagDataLayer.current)
      }
      if (tagGTM.current) {
        TagFunctions.removeTags(tagGTM.current)
      }
    }
  }, [sale])

  useEffect(() => {
    if (sale) {
      if (isRedirect) {
        if (redirectSeconds === 0 && sale.redirectUrl) {
          const url = CheckoutFunctions.getUrlQueryThankyouPage(
            sale.redirectUrl,
            sale
          )
          window.location.href = Hosts.getUrlRedirect(url)
          return
        }

        setTimeout(() => {
          setRedirectSeconds((redirectSeconds) => redirectSeconds - 1)
        }, 1000)
      }

      const redirect = query.get("redirect")
      if (
        redirect !== "no" &&
        sale.redirectUrl !== undefined &&
        sale.redirectUrl !== ""
      ) {
        setIsRedirect(true)
      }
    }
  }, [isRedirect, redirectSeconds, sale])

  return (
    <Container component={"main"} maxWidth="md">
      {!isRedirect && (
        <Stack direction="column" spacing={3} sx={{ pt: 8 }}>
          <Paper
            square={true}
            elevation={0}
            sx={{ width: "100%", borderRadius: 5 }}
          >
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              width="100%"
              sx={{ py: { sm: 3, xs: 2 } }}
              spacing={2}
            >
              <Stack sx={{ px: { sm: 5, xs: 2 }, width: "100%" }}>
                <Paper
                  square={true}
                  elevation={0}
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: "#1E88E5",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    sx={{ py: 3, px: { sm: 5, xs: 1 } }}
                  >
                    <SuccessIcon sx={{ fontSize: "3.5em", color: "#212121" }} />
                    <Stack direction="column">
                      <Typography variant="h5">
                        Parabéns {sale?.customer.name}
                      </Typography>
                      <Typography variant="h4" fontWeight={600}>
                        Sua compra foi aprovada!
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
              </Stack>
              <Container maxWidth="sm">
                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Para acessar seu curso siga os passos abaixo:
                </Typography>
                <Typography variant="body1" gutterBottom>
                  1. Acesse seu email {sale?.customer.email}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  2. Busque pelo login e senha do seu produto{" "}
                  {sale?.productName}
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  3. Comece agora mesmo a aprender
                </Typography>

                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Aviso importante
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  Em alguns casos seu email pode acabar se enganando e colocando
                  o nosso email na sua área de SPAM, por isso, verifique sempre
                  essa área.
                </Typography>

                <Paper
                  square={true}
                  elevation={0}
                  sx={{
                    width: "100%",
                    borderRadius: 1,
                    backgroundColor: grey[100],
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ p: 3 }}
                  >
                    <Typography variant="body1">
                      Seu código de transação:{" "}
                      <strong>{sale?.orderCode}</strong>
                    </Typography>
                  </Stack>
                </Paper>
              </Container>
            </Stack>
          </Paper>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            width="100%"
            spacing={2}
          >
            <Container maxWidth="sm">
              <Stack direction="column" width="100%" sx={{ width: "100%" }}>
                <Typography variant="body1" fontWeight={600} gutterBottom>
                  Precisa de ajuda?
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: 3 }}>
                  Fale agora mesmo com o responsável pela venda.
                </Typography>

                <Typography variant="body1" gutterBottom>
                  <strong>Nome: </strong>
                  {sale?.sellerName}
                </Typography>
                <Typography variant="body1">
                  <strong>Email: </strong>
                  {sale?.sellerEmail}
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Stack>
      )}

      {isRedirect && (
        <Stack
          direction="column"
          spacing={3}
          alignContent="center"
          alignItems="center"
          sx={{ pt: 8 }}
        >
          <Typography variant="body1" sx={{ mt: 2 }} data-cy="successRedirect">
            Você será redirecionado para a pagina de confirmação em{" "}
            {redirectSeconds} segundos, aguarde...
          </Typography>
        </Stack>
      )}
    </Container>
  )
}

export default CheckoutSuccessPage
