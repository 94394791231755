import api, { getResponseError } from "../../services/api";
import { TResponse } from "../types/ErrorResponse";
import { TProductVoucherApply } from "../types/Voucher";

const applyVoucher = async (data: { productId: string, productPriceId: string, voucherIdentifier: string }): Promise<TResponse<TProductVoucherApply>> => {
    try {
        const { data: response } = await api.post("/ProductVoucher/voucher/apply", data);
        if (response) {
            return {
                success: true,
                code: 0,
                error: '',
                data: response,
            };
        }

        return {
            success: false,
            code: 1,
            error: 'Erro inesperado do servidor',
        };
    } catch (error: any) {
        const e = getResponseError(error);
        return {
            success: false,
            code: 2,
            error: e,
        };
    }
}

const VoucherController = {
    applyVoucher,
}

export default VoucherController;