import {
  Box,
  FormControl,
  InputBase,
  InputBaseComponentProps,
  InputLabel,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Control, Controller, UseFormClearErrors } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #C5C6C9",
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "white",
  },
  invalid: {
    border: "2px solid red",
    borderRadius: "4px",
    padding: "10px !important",
    backgroundColor: "white",
  },
  valid: {
    border: "2px solid #BCC1FF",
    borderRadius: "4px",
    padding: "10px !important",
    backgroundColor: "white",
  },
  rootSmall: {
    border: "1px solid #C5C6C9",
    borderRadius: "4px",
    padding: "10px",
    backgroundColor: "white",
    height: "46px",
  },
  invalidSmall: {
    border: "2px solid red",
    borderRadius: "4px",
    padding: "10px !important",
    backgroundColor: "white",
    height: "46px",
  },
  validSmall: {
    border: "2px solid #BCC1FF",
    borderRadius: "4px",
    padding: "10px !important",
    backgroundColor: "white",
    height: "46px",
  },
}));

export interface IValidationTextFieldProp {
  control: Control<any, any>;
  fieldName: string;
  label: string;
  placeholder?: string;
  inputProps?: InputBaseComponentProps;
  required?: boolean;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  backgroundColorTitle?: string | undefined;
  shrink?: boolean;
  sxInputLabel?: SxProps<Theme>;
  onKeyDown?: React.KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement | HTMLDivElement
  >;
  onFieldExit?: () => void;
  clearErrors: UseFormClearErrors<any>;
  validate?: (value?: any) => boolean;
  validateLabel?: string;
  dataCyErrorLabel?: string;
  size?: "small";
  variant?: "default" | "labeled";
}

function TextFieldRequired({
  control,
  fieldName,
  label,
  placeholder,
  inputProps,
  required = true,
  startAdornment,
  endAdornment,
  backgroundColorTitle,
  shrink,
  sxInputLabel,
  onKeyDown,
  onFieldExit,
  clearErrors,
  validate,
  validateLabel,
  dataCyErrorLabel,
  size,
  variant = "default",
}: IValidationTextFieldProp) {
  const classes = useStyles();
  const { register } = control;

  const [isValid, setIsValid] = useState(true);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState("");

  const validateField = (value?: string) => {
    if (!validate) {
      setIsValid(true);
      return;
    }

    const valid = validate!(value);
    setIsValid(valid);
  };

  useEffect(() => {
    register(fieldName, { required });
  }, [required]);

  return (
    <FormControl fullWidth size={size}>
      {variant === "labeled" ? (
        <Typography
          sx={{ marginBottom: 1.5 }}
          fontSize="14px"
          fontWeight={500}
          lineHeight="18px"
          color="black"
        >
          {label}
        </Typography>
      ) : (
        <InputLabel
          id={fieldName}
          htmlFor={fieldName}
          sx={{
            background:
              (focused || value !== "") && backgroundColorTitle
                ? `linear-gradient(${backgroundColorTitle}, white)`
                : "white",
            backgroundColor: "white",
            px: 1,
            fontSize: "14px",
            lineHeight: "24px",
            color: "#38393B",
            ...sxInputLabel,
          }}
          shrink={shrink}
        >
          {label}
        </InputLabel>
      )}

      <Controller
        control={control}
        name={fieldName}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => {
          let className =
            error?.type === "required" || !isValid
              ? classes.invalid
              : value !== ""
              ? classes.valid
              : classes.root;

          if (size === "small") {
            className =
              error?.type === "required" || !isValid
                ? classes.invalidSmall
                : value !== ""
                ? classes.validSmall
                : classes.rootSmall;
          }

          return (
            <Box>
              <InputBase
                fullWidth
                required={required}
                id={fieldName}
                type="text"
                value={value}
                //defaultValue=""
                placeholder={variant === "labeled" ? placeholder : ""}
                error={error?.type === "required" || !isValid}
                className={className}
                onBlur={(e) => {
                  try {
                    const text = e.target.value;
                    validateField(text);
                  } catch (err) {
                    console.log(err);
                  }
                  onBlur();
                  if (onFieldExit) {
                    onFieldExit();
                  }
                  setFocused(false);
                }}
                onChange={(e) => {
                  try {
                    const text = e.target.value;
                    if (text !== "") {
                      clearErrors(fieldName);
                      setIsValid(true);
                    }
                    setValue(text);
                  } catch (err) {
                    console.log(err);
                  }
                  onChange(e);
                }}
                onFocus={() => setFocused(true)}
                inputRef={ref}
                inputProps={inputProps}
                startAdornment={startAdornment}
                endAdornment={endAdornment}
                onKeyDown={onKeyDown}
                sx={{
                  borderRadius: "10px",
                }}
              />
              {invalid && (
                <Typography
                  variant="overline"
                  color="red"
                  data-cy={dataCyErrorLabel}
                  data-testid={dataCyErrorLabel}
                >
                  Campo {`"${label}"`} obrigatório
                </Typography>
              )}
              {!isValid && (
                <Typography
                  variant="overline"
                  color={"red"}
                  data-cy={dataCyErrorLabel}
                >
                  {validateLabel || `${label} inválido`}
                </Typography>
              )}
            </Box>
          );
        }}
      />
    </FormControl>
  );
}

export default TextFieldRequired;
