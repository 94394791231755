export const maskPhoneNumber = (value: String | undefined) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)/, '$1')
}

export const maskCpfNumber = (v: String | undefined): string => {
    if (!v) return '';

    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{3})(\d)/g, '$1.$2')
    v = v.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, '$1.$2.$3-$4')
    v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, '$1.$2.$3-$4')
    return v.substring(0, 14);
}

export const maskCnpjNumber = (value: String | undefined) => {
    if (!value) return '';

    return value.replace(/[\D]/g, '')
        .replace(/(\d{2})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1/$2')
        .replace(/(\d{4})(\d)/, '$1-$2')
        .replace(/(-\d{2})\d+?$/, '$1');
}

export const maskCepNumber = (value: String | undefined) => {
    if (!value) return '';
    return value.replace(/\D/g, "")
        .replace(/^(\d{5})(\d{3})+?$/, "$1-$2")
        .replace(/(-\d{3})(\d+?)/, '$1');
}

export const maskMonthYear = (value: String | undefined) => {
    if (!value) return '';
    return value.replace(/\D/g, "")
        .replace(/^(\d{2})(\d)/g, '$1/$2')
        .replace(/^(\d{2})\/(\d{2})(\d)/, '$1');
}

export const maskCardNumber = (v: String | undefined): string => {
    if (!v) return '';

    v = v.replace(/\D/g, '')
    v = v.replace(/^(\d{4})(\d)/g, '$1 $2')
    v = v.replace(/^(\d{4}) (\d{4})(\d)/, '$1 $2 $3')
    v = v.replace(/^(\d{4}) (\d{4}) (\d{4})(\d)/, '$1 $2 $3 $4')
    v = v.replace(/^(\d{4}) (\d{4}) (\d{4}) (\d{4})(\d)/, '$1 $2 $3 $4')
    return v.substring(0, 19);
}

export const numberOnly = (value: String | undefined) => {
    if (!value) return '';

    const numberPattern = /\d+/g;
    const numbers = value.match(numberPattern)?.join('');
    return numbers || '';
}