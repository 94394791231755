import { Box, Link, Stack, Typography } from "@mui/material";
import React from "react";
import CoverImageSecurity5 from "./CoverImageSecurity";

export interface ICheckoutFooter5 {
  tenantName?: string;
  setOpenTermsPurchase: (value: boolean) => void;
}

const CheckoutFooter5 = ({
  tenantName,
  setOpenTermsPurchase,
}: ICheckoutFooter5) => {
  return (
    <Stack direction="column" textAlign={"center"} spacing={2} sx={{ pt: 2 }}>
      <CoverImageSecurity5 />
      <Box sx={{ py: 1 }}>
        <img src="/assets/celetusLogo/celetus_grayLogo.png" alt="" />
      </Box>
      {tenantName && (
        <Typography
          variant="body2"
          fontSize="14"
          lineHeight="18px"
          sx={{
            mt: 1,
            color: "#767171",
          }}
        >
          Nós estamos processando o pagamento em nome de {tenantName ?? ""}
        </Typography>
      )}

      <Typography
        variant="body2"
        fontSize="14"
        lineHeight="18px"
        sx={{
          color: "#767171",
          mb: 2,
        }}
      >
        Esse site é protegido pelo reCAPTCHA do Google{" "}
        <Link
          href="https://policies.google.com/privacy"
          sx={{
            color: "#1C52BD",
          }}
        >
          Política de Privacidade
        </Link>
        , e{" "}
        <Link
          href="https://policies.google.com/terms"
          sx={{
            color: "#1C52BD",
          }}
        >
          Termos de Serviço
        </Link>
        , *Parcelamento com acréscimo de 2.99% a.m. Ao prosseguir você concorda
        com os{" "}
        <Link
          underline="hover"
          onClick={() => setOpenTermsPurchase(true)}
          sx={{
            color: "#5D5E61",
          }}
        >
          Termos de Compra
        </Link>
      </Typography>

      <Typography
        variant="body2"
        textAlign={"center"}
        fontSize="14"
        sx={{
          color: "#5D5E61",
        }}
      >
        Celetus &copy; 2024 - Todos os direitos reservados.
      </Typography>
    </Stack>
  );
};

export default CheckoutFooter5;
