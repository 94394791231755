import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../core/types/Product"
import PaymentsByProduct from "../../../../pages/Checkout/Payment"
import { TSale } from "../../../../core/types/Sale"
import React from "react"

export interface ICheckoutPayments2 {
  productPriceId: string
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

const CheckoutPayments2 = ({
  productPriceId,
  loading,
  data,
  control,
  watch,
  setValue,
  errors,
  installments,
  clearErrors,
}: ICheckoutPayments2) => {
  return (
    <PaymentsByProduct
      productPriceId={productPriceId}
      data={data}
      loading={loading}
      control={control}
      setValue={setValue}
      watch={watch}
      errors={errors}
      installments={installments}
      clearErrors={clearErrors}
    />
  )
}

export default CheckoutPayments2
