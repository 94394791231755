import moment from "moment"

export const oneSecondInMilleseconds = 1000

export const oneMinuteInMilliseconds = oneSecondInMilleseconds * 60

export const oneHourInMilliseconds = oneMinuteInMilliseconds * 60

export const yearsExpires = () => {
  const now = new Date()
  const year = now.getFullYear()
  const years: number[] = []

  for (var i = year; i < year + 10; i++) {
    years.push(Number(i.toString().substring(2, 4)))
  }

  return years
}

export const addDays = (days: number, dateTime?: Date) => {
  dateTime = dateTime || new Date()
  const current = new Date(dateTime)
  current.setDate(current.getDate() + days)
  return current
}

export const addMinutes = (minutes: number, dateTime?: Date) => {
  dateTime ??= new Date()
  const current = new Date(dateTime)
  current.setMinutes(current.getMinutes() + minutes)
  return current
}

export const dateTimeToAPI = (dateTime?: Date) => {
  if (!dateTime) {
    return ""
  }
  return moment(dateTime).format("yyyy-MM-DD HH:mm:ss")
}

export const diffDays = (dateA: Date, dateB: Date) => {
  const timeA = dateA.getTime()
  const timeB = dateB.getTime()

  const diff = timeA - timeB
  const diffInDays = Math.ceil(diff / (1000 * 3600 * 24))

  return diffInDays
}

export const diffHours = (dateA: Date, dateB: Date) => {
  const timeA = dateA.getTime()
  const timeB = dateB.getTime()

  const diff = timeA - timeB
  const diffInHours = Math.ceil(diff / (1000 * 3600))

  return diffInHours
}

export const diffMinutes = (dateA: Date, dateB: Date) => {
  const timeA = dateA.getTime()
  const timeB = dateB.getTime()

  const diff = timeA - timeB
  const diffInMinutes = Math.ceil(diff / (1000 * 60))

  return diffInMinutes
}

export const diffSeconds = (dateA: Date, dateB: Date) => {
  const timeA = dateA.getTime()
  const timeB = dateB.getTime()

  const diff = Math.abs(timeA - timeB)
  const diffInSeconds = Math.floor(diff / 1000)

  return diffInSeconds
}

export function getMaxDate(dates: Date[]): Date | null {
  if (dates.length === 0) return null

  let maxDate = dates[0]

  for (let i = 1; i < dates.length; i++) {
    if (dates[i] > maxDate) {
      maxDate = dates[i]
    }
  }

  return maxDate
}
