import { Box, Stack, Typography } from "@mui/material";

const PixPaymentPrice5 = ({ visible }: { visible: boolean }) => {
  return (
    <Stack
      direction={"column"}
      spacing={2}
      p={0.5}
      sx={{ display: visible ? undefined : "none" }}
    >
      <Box>
        <Typography
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
          color="rgba(99, 126, 239, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/pix/approved.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          IMEDIATO
        </Typography>
        <Typography
          fontSize="12px"
          lineHeight="16px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
        >
          Ao selecionar a opção Gerar Pix o código para pagamento estará
          disponível.
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
          color="rgba(99, 126, 239, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/pix/payment.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          PAGAMENTO SIMPLES
        </Typography>
        <Typography
          fontSize="12px"
          lineHeight="16px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
        >
          Para pagar basta abrir o aplicativo do seu banco, procurar pelo PIX e
          escanear o QRcode.
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize="16px"
          lineHeight="20px"
          fontWeight={700}
          color="rgba(99, 126, 239, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/pix/security.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          100% SEGURO
        </Typography>
        <Typography
          fontSize="12px"
          lineHeight="16px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
        >
          O pagamento com PIX foi desenvolvido pelo Banco Central para facilitar
          suas compras.
        </Typography>
      </Box>
    </Stack>
  );
};

export default PixPaymentPrice5;
