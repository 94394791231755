import { Box, ListItem, ListItemButton, Stack, Switch, Typography } from "@mui/material";
import { IOrderBump } from "../../../core/types/OrderBump";
import { grey, red } from "@mui/material/colors";
import numbers from "../../../core/functions/NumberFunctions";
import React from "react";

export interface ICheckoutOrderBumpProp {
    orderBump: IOrderBump;
    onChange(orderBump: IOrderBump): void;
}

const CheckoutOrderBump = ({ orderBump, onChange }: ICheckoutOrderBumpProp): JSX.Element => {
    const handleClick = () => {
        orderBump.selected = !orderBump.selected;
        onChange(orderBump);
    }

    return (
        <ListItem
            key={`ob-${orderBump.id}`}
            divider
            disablePadding
            sx={{
                border: `2px solid ${red[700]}`,
                borderStyle: "dashed",
                borderRadius: 2,
                mb: 1,
                backgroundColor: "rgba(255, 255, 224, 0.2)",
            }}
        >
            <ListItemButton role={undefined} onClick={handleClick} dense>
                <Stack direction={"column"} spacing={1} sx={{ width: '100%', p: 1 }} alignContent={"center"} alignItems={"center"}>
                    <Typography variant="body1" fontWeight={500} color={grey[800]} textAlign={"center"}>
                        {orderBump.title || ''}
                    </Typography>
                    <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {orderBump.description || ''}
                    </Typography>
                    <Stack direction="row" spacing={1} sx={{ width: '100%' }} alignContent={"center"} alignItems={"center"}>
                        <Switch checked={orderBump.selected} />
                        <Box sx={{ height: 60, width: 60 }}>
                            {orderBump.productPrice?.product?.coverUrl && orderBump.productPrice?.product?.coverUrl !== '' &&
                                <img
                                    src={!orderBump.productPrice?.product?.coverUrl ? `${process.env.PUBLIC_URL}/top.png` : orderBump.productPrice.product.coverUrl}
                                    alt={orderBump.productPrice?.product?.name}
                                    style={{ height: 60, width: 60, objectFit: 'cover', borderRadius: 5, }}
                                />
                            }
                        </Box>
                        <Typography variant="body1">
                            {orderBump.descriptionPrice || ''} <strong style={{ color: red[700] }}>
                                {` + ${numbers.formatMoneyDefault(orderBump.productPrice?.firstPurchasePrice || orderBump.productPrice?.value)}`}
                            </strong>
                        </Typography>
                    </Stack>
                </Stack>
            </ListItemButton>
        </ListItem>
    );
}

export default CheckoutOrderBump;