import React from "react"
import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutCoverImage2 from "./Layouts/Layout2/CheckoutCoverImage2"
import CheckoutCoverImageDefault from "./Layouts/LayoutDefault/CheckoutCoverImageDefault"
import CheckoutCoverImage3 from "./Layouts/Layout3/CheckoutCoverImage3"
import { IProductPricePaymentInstallment } from "../../core/types/Product"
import CheckoutCoverImage4 from "./Layouts/Layout4/CheckoutCoverImage4"
import CheckoutCoverImage5 from "./Layouts/Layout5/CheckoutCoverImage5"

export interface ICheckoutCoverImage {
  layout?: TCheckoutLayout
  imageUrl?: string
  productImageUrl?: string
  productName: string
  productDescription: string
  productInstallments: IProductPricePaymentInstallment[] | undefined
  isCheckoutBuilder: boolean
  productValue: number
  offerName: string
}

const CheckoutCoverImage = ({
  layout,
  imageUrl,
  productImageUrl,
  productName,
  productDescription,
  productInstallments,
  isCheckoutBuilder,
  productValue,
  offerName,
}: ICheckoutCoverImage) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return <CheckoutCoverImageDefault imageUrl={imageUrl} />
    case TCheckoutLayout.Layout2:
      return <CheckoutCoverImage2 imageUrl={imageUrl} />
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutCoverImage3
          imageUrl={imageUrl}
          productImageUrl={productImageUrl}
          productName={productName}
          productDescription={productDescription}
          productInstallments={productInstallments}
          isCheckoutBuilder={isCheckoutBuilder}
          productValue={productValue}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutCoverImage4
          imageUrl={imageUrl}
          productImageUrl={productImageUrl}
          productName={productName}
          productDescription={productDescription}
          productInstallments={productInstallments}
          isCheckoutBuilder={isCheckoutBuilder}
          productValue={productValue}
          offerName={offerName}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutCoverImage5
          imageUrl={imageUrl}
          productImageUrl={productImageUrl}
          productName={productName}
          productDescription={productDescription}
          productInstallments={productInstallments}
          isCheckoutBuilder={isCheckoutBuilder}
          productValue={productValue}
          offerName={offerName}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutCoverImage
