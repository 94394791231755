import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../core/types/Product"
import { TSale } from "../../../../core/types/Sale"
import React from "react"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import { TCheckoutLayout } from "../../../../core/types/Checkout"
import PaymentsByProduct4 from "./Payment"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import LocalOfferIcon from "@mui/icons-material/LocalOffer"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import { TProductVoucherValue } from "../../../../core/types/Voucher"
import NumberFunctions from "../../../../core/functions/NumberFunctions"

export interface ICheckoutPayments4 {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  loadingVoucher: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

const CheckoutPayments4 = ({
  layout,
  productPriceId,
  loading,
  loadingVoucher,
  data,
  control,
  watch,
  setValue,
  errors,
  installments,
  clearErrors,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutPayments4) => {
  const voucherIdentifier = watch("payment.voucherIdentifier")
  const voucherStatus = watch("payment.voucherStatus")
  const voucherType = watch("payment.voucherType")
  const voucherValue = watch("payment.voucherValue")

  return (
    <Box sx={{ pt: 1 }}>
      {data?.hasVoucher && (
        <Box sx={{ mb: 3, border: "1px solid #DCDDDE", borderRadius: "4px" }}>
          <Accordion elevation={0} data-cy="panelVoucherContent">
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-voucher-content"
              id="panel-voucher-header"
            >
              {["none", "invalid"].includes(voucherStatus) && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <LocalOfferIcon
                    sx={{
                      color: "white",
                      stroke: "#323232",
                      strokeWidth: 1.5,
                      fontSize: "1.3rem",
                    }}
                  />
                  <Typography fontSize="14px" lineHeight="18px">
                    Tem um cupom de desconto?
                  </Typography>
                </Stack>
              )}
              {["valid"].includes(voucherStatus) && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <CheckCircleOutlineIcon
                    color="primary"
                    sx={{ fontSize: "1.3rem" }}
                  />
                  <Typography fontSize="14px" lineHeight="18px">
                    Cupom <strong>{voucherIdentifier}</strong> aplicado!
                  </Typography>
                </Stack>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" spacing={2} alignItems="start">
                <TextField
                  fullWidth
                  size="small"
                  disabled={loadingVoucher || voucherStatus === "valid"}
                  {...control.register("payment.voucherIdentifier")}
                  error={voucherStatus === "invalid"}
                  helperText={
                    voucherStatus === "valid"
                      ? `Você recebeu um cupom de ${
                          voucherType === TProductVoucherValue.value
                            ? NumberFunctions.formatMoneyDefault(voucherValue)
                            : NumberFunctions.toPercentage(voucherValue)
                        }`
                      : ""
                  }
                  id="voucherIdentifier"
                />
                {["none", "invalid"].includes(voucherStatus) && (
                  <Button
                    id="buttonVoucherApply"
                    variant="contained"
                    size="large"
                    sx={{ textTransform: "none" }}
                    onClick={onApplyVoucher}
                    disabled={
                      loadingVoucher || (voucherIdentifier ?? "") === ""
                    }
                  >
                    Aplicar
                  </Button>
                )}
                {["valid"].includes(voucherStatus) && (
                  <Button
                    id="buttonVoucherRemove"
                    variant="outlined"
                    color="error"
                    size="large"
                    sx={{ textTransform: "none" }}
                    onClick={onRemoveVoucher}
                    disabled={loadingVoucher}
                  >
                    Excluir
                  </Button>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        </Box>
      )}

      <Typography
        variant="body1"
        fontSize="16px"
        fontWeight={700}
        lineHeight="20px"
        gutterBottom
      >
        Informações de pagamento
      </Typography>
      <PaymentsByProduct4
        layout={layout}
        productPriceId={productPriceId}
        data={data}
        loading={loading}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        installments={installments}
        clearErrors={clearErrors}
      />
    </Box>
  )
}

export default CheckoutPayments4
