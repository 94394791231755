import { CircularProgress, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "../../../components/Query/useQuery";
import { useEffect } from "react";
import { useLocalStorage } from "../../../core/hooks/useLocalStorage";
import CheckoutFunctions from "../../../core/functions/CheckoutFunctions";
import Hosts from "../../../core/functions/HostFunctions";

const OrderThanksRedirectPage = () => {
    const { codeId } = useParams<{ codeId?: string; }>();

    const [, setSaleStorage] = useLocalStorage("afpa:sale", null);

    const query = useQuery();
    const navigate = useNavigate();

    useEffect(() => {
        const sale = CheckoutFunctions.getSaleStorageByQuery(query);
        if (sale.paymentType === 0) {
            Hosts.openNavigate(`/${codeId}`, navigate);
        } else {
            setSaleStorage(sale);
            Hosts.openNavigate(`/${codeId}/thanks`, navigate);
        }
    }, []);

    return (
        <Stack
            direction={"column"}
            width={"100%"}
            sx={{ p: 3 }}
            spacing={3}
            alignItems="center"
            justifyContent="center"
        >
            <Stack direction="column" alignItems="center">
                <Typography variant="body1" fontWeight={500} textAlign="center">Carregando...</Typography>
            </Stack>
            <CircularProgress size="2rem" />
        </Stack>
    );
}

export default OrderThanksRedirectPage;