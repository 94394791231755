import { IconButton, Stack, Typography } from '@mui/material'

export interface ISwipeableDrawerTitleProps {
  title: string | React.ReactNode
  subtitle?: JSX.Element
  setOpen: (value: boolean) => void
}

const SwipeableDrawerTitle = ({
  title,
  subtitle,
  setOpen,
}: ISwipeableDrawerTitleProps) => {
  return (
    <Stack direction="row" alignItems="start" justifyContent="space-between">
      <Stack direction="column" sx={{ py: 1 }}>
        <Typography
          fontSize="20px"
          lineHeight="24px"
          fontWeight={700}
          color="#343948"
        >
          {title}
        </Typography>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={400}
          color="#707275"
        >
          {subtitle}
        </Typography>
      </Stack>
      <IconButton size="small" onClick={() => setOpen(false)}>
        <img src="/assets/icons/actions/close.svg" alt="edit" />
      </IconButton>
    </Stack>
  )
}

export default SwipeableDrawerTitle
