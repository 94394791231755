import api, { getResponseError } from "../../services/api"
import { ICalculateInstallment, IOrder, IOrderCharge } from "../types/Order"
import { IErrorResponse, IResponse } from "../types/ErrorResponse"
import { TSale, TSaleResult } from "../types/Sale"
import { methodTypeCreditCard, methodTypeDebitCard } from "../constants"
import { numberOnly } from "../../masks/masks"
import { TProductLinkAffiliated } from "../types/Product"
import { TCheckoutAccess } from "../types/Checkout"

const create = async ({ data }: { data: TSale }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("/Order", setSendData(data))
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const setSendData = (data: TSale) => {
  const item: IOrder = {
    checkoutOrderId: data.checkoutOrderId,
    lostSaleCode: data.lostSaleCode,
    productPriceId: data.productPriceId,
    name: data.name.trim(),
    document: numberOnly(data.document),
    email: data.email.trim().toLowerCase(),
    phone: numberOnly(data.phone),
    affiliateCode: data.onlyProducer ? null : data.affiliateCode,
    src: data.src,
    sck: data.sck,
    utm_campaign: data.utm_campaign,
    utm_source: data.utm_source,
    utm_medium: data.utm_medium,
    utm_content: data.utm_content,
    utm_term: data.utm_term,
    checkoutModelId: data.checkoutModelId,
    kondutoId: data.kondutoId,
    onlyProducer: data.onlyProducer,
    pageAccessId: data.pageAccessId,
    clientIP: data.clientIP,
    recaptchaToken: data.recaptchaToken,
    address: {
      city: data.address.city.trim(),
      complement: data.address.complement?.trim(),
      neighborhood: data.address.neighborhood.trim(),
      number: data.address.number.trim(),
      reference: data.address.reference?.trim(),
      state: data.address.state.trim(),
      street: data.address.street.trim(),
      zipCode: numberOnly(data.address.zipCode),
    },
    paymentData: {
      paymentMethod: data.payment.methodType,
      discount: data.payment.discount,
      subtotal: data.payment.subtotal,
      total: data.payment.total,
      voucherStatus: data.payment.voucherStatus,
      voucherId: data.payment.voucherId,
      voucherIdentifier: data.payment.voucherIdentifier,
      voucherType: data.payment.voucherType,
      voucherValue: data.payment.voucherValue,
    },
  }

  if (data.payment.cardValidate && data.payment.cardValidate !== null) {
    if (data.payment.cardValidate.includes("/")) {
      const cardValidate = data.payment.cardValidate.split("/")
      data.payment.cardExpiresMonth = Number(cardValidate[0])
      data.payment.cardExpiresYear = Number(cardValidate[1])
    }
  }

  if (item.paymentData.paymentMethod === methodTypeCreditCard) {
    item.paymentData.creditCardData = {
      number: numberOnly(data.payment.cardNumber),
      name: data.payment.cardName?.trim(),
      cvv: data.payment.cardCCV?.trim(),
      year: data.payment.cardExpiresYear ?? 0,
      month: data.payment.cardExpiresMonth ?? 0,
      installments: data.payment.installment,
    }
  }

  if (item.paymentData.paymentMethod === methodTypeDebitCard) {
    item.paymentData.debitCardData = {
      number: numberOnly(data.payment.cardNumber),
      name: data.payment.cardName?.trim(),
      cvv: data.payment.cardCCV?.trim(),
      year: data.payment.cardExpiresYear ?? 0,
      month: data.payment.cardExpiresMonth ?? 0,
    }
  }

  if (data?.products) {
    item.orderBumps = data.products.map((x) => ({
      productPriceId: x.productPriceId,
      description: x.productName?.trim(),
      amount: x.amount,
      value: x.value,
    }))
  }

  return item
}

const verified = async ({
  chargeId,
}: {
  chargeId?: string
}): Promise<IOrderCharge | undefined> => {
  if (!chargeId) {
    return undefined
  }
  try {
    const { data: response } = await api.get(`/Order/charge/${chargeId}`)
    if (response) {
      return response
    }

    return undefined
  } catch (error: any) {
    return undefined
  }
}

const calculeIsntallments = async ({
  priceId,
  newValue,
}: {
  priceId: string
  newValue: number
}): Promise<ICalculateInstallment | IErrorResponse> => {
  try {
    const { data: response } = await api.get(
      `/Order/price/calculate/${priceId}`,
      { params: { NewValue: newValue } }
    )
    if (response) {
      return response
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getCheckoutOrder = async ({
  checkoutOrderCodeId,
  afid,
}: {
  checkoutOrderCodeId?: string
  afid?: string | null
}): Promise<TSaleResult | undefined> => {
  if (!checkoutOrderCodeId) {
    return undefined
  }
  try {
    const { data: response } = await api.get(
      `/Order/code/${checkoutOrderCodeId}?afid=${afid}`
    )
    if (response) {
      return response
    }

    return undefined
  } catch (error: any) {
    return undefined
  }
}

const getCheckoutOrderTest = async ({
  checkoutOrderCodeId,
  afid,
}: {
  checkoutOrderCodeId?: string
  afid?: string | null
}): Promise<TSaleResult | undefined> => {
  if (!checkoutOrderCodeId) {
    return undefined
  }
  try {
    const { data: response } = await api.get(
      `/Order/code/${checkoutOrderCodeId}/test?afid=${afid}`
    )
    if (response) {
      return response
    }

    return undefined
  } catch (error: any) {
    return undefined
  }
}

const getProductLinkAffiliated = async ({
  affiliatedCodeId,
  ap,
  clientIp,
  userAgent,
  browser,
  language,
  pageUrl,
}: {
  affiliatedCodeId?: string
  ap?: string | null
  clientIp?: string | null
  userAgent?: string | null
  browser?: string | null
  language?: string | null
  pageUrl?: string | null
}): Promise<TProductLinkAffiliated | null> => {
  if (!affiliatedCodeId) {
    return null
  }
  try {
    const url = `/Order/link/affiliated/${affiliatedCodeId}?ap=${ap ?? ""}&ci=${
      clientIp ?? ""
    }&ua=${userAgent ?? ""}&br=${browser ?? ""}&la=${language ?? ""}&pu=${
      pageUrl ?? ""
    }`
    const { data: response } = await api.get<TProductLinkAffiliated>(url)
    if (response) {
      return response
    }

    return null
  } catch (error: any) {
    return null
  }
}

const registerAccess = async ({
  data,
}: {
  data: TCheckoutAccess
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("Order/register/access", data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const OrderController = {
  create,
  verified,
  calculeIsntallments,
  getCheckoutOrder,
  getCheckoutOrderTest,
  getProductLinkAffiliated,
  registerAccess,
}

export default OrderController
