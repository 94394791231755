import api, { getResponseError } from "../../services/api"
import { dateTimeToAPI } from "../functions/dateTime"
import { TCheckoutLayout } from "../types/Checkout"
import { IErrorResponse, IResponse } from "../types/ErrorResponse"
import {
  IProduct,
  IProductPagination,
  IProductPrice,
  IProductSimple,
  TPriceAffiliated,
  TProductLinkPageAccess,
  TProductPixelType,
} from "../types/Product"

interface IGetProductProp {
  page: number
  rows: number
  name?: string
}

interface IGetPaymentByPrice {
  priceCode: string
  afid?: string | null
  plid?: string | null
  afcd?: Date | null
  clientIp?: string | null
  onlyProducer?: boolean
  model?: TCheckoutLayout
}

const getAll = async ({
  page,
  rows,
  name,
}: IGetProductProp): Promise<IProductPagination | IErrorResponse> => {
  try {
    var path = `/Product?PageNumber=${page}&RowsPerPage=${rows}`

    if (name && name !== "") {
      path += `&Name=${name}`
    }

    const { data } = await api.get<IProductPagination>(path)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getList = async (): Promise<IProductSimple[] | IErrorResponse> => {
  try {
    const { data } = await api.get<IProductSimple[]>("/Product/list")
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getPricesByProduct = async ({
  productId,
}: {
  productId: string
}): Promise<IProductPrice[] | IErrorResponse> => {
  try {
    const { data } = await api.get<IProductPrice[]>(
      `/Product/price/${productId}/list`
    )
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const get = async ({
  id,
}: {
  id: string
}): Promise<IProduct | IErrorResponse> => {
  try {
    const { data } = await api.get<IProduct>(`/Product/${id}`)
    if (data) {
      const response = await getPricesByProduct({ productId: id })
      const err = response as IErrorResponse
      if (err.code) {
        return err
      }
      const prices = response as IProductPrice[]
      data.prices = prices
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const insert = async ({ data }: { data: IProduct }): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("/Product", data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const update = async ({ data }: { data: IProduct }): Promise<IResponse> => {
  try {
    const json = {
      id: data.id,
      memberServiceId: data.memberServiceId,
      name: data.name,
      description: data.description,
      approvedPageLink: data.approvedPageLink,
      awaitingPaymentPageLink: data.awaitingPaymentPageLink,
      awaitingCreditAnalysisPageLink: data.awaitingCreditAnalysisPageLink,
      facebookPixel: data.facebookPixel || "",
    }
    const { data: response } = await api.put("/Product", json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertPrice = async ({
  data,
}: {
  data: IProductPrice
}): Promise<IResponse> => {
  try {
    const json = {
      productId: data.productId,
      value: Number(data.value || 0),
      paymentMethods: data.paymentMethods,
    }
    const { data: response } = await api.post("/Product/price", json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const updatePrice = async ({
  data,
}: {
  data: IProductPrice
}): Promise<IResponse> => {
  try {
    const json = {
      id: data.id,
      value: Number(data.value || 0),
      paymentMethods: data.paymentMethods,
    }
    const { data: response } = await api.put("/Product/price", json)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const deletePrice = async ({
  priceId,
}: {
  priceId: string
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.delete(`/Product/price/${priceId}`)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const getPaymentByPrice = async ({
  priceCode,
  model,
}: IGetPaymentByPrice): Promise<IProductPrice | IErrorResponse> => {
  try {
    var url = `/Order/price/code/${priceCode}`

    if (model) {
      url += `?model=${Number(model)}`
    }

    const { data } = await api.get<IProductPrice>(url)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    console.error(error)
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getPriceAffiliatedByCode = async ({
  priceCode,
  afid,
  plid,
  afcd,
  clientIp,
  onlyProducer,
}: IGetPaymentByPrice): Promise<TPriceAffiliated | IErrorResponse> => {
  try {
    var url = `/Order/price/code/${priceCode}/affiliated?ci=${
      clientIp ?? ""
    }&op=${onlyProducer ?? false}`
    if (afid) {
      url += `&afid=${afid}`
    }
    if (plid) {
      url += `&plid=${plid}`
    }
    if (afcd) {
      url += `&afcd=${dateTimeToAPI(afcd)}`
    }

    const { data } = await api.get<TPriceAffiliated>(url)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    console.error(error)
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const getPaymentByPriceTest = async ({
  priceCode,
  afid,
  plid,
  afcd,
}: IGetPaymentByPrice): Promise<IProductPrice | IErrorResponse> => {
  try {
    var url = `/Order/price/code/${priceCode}/test`
    if (afid) {
      url += `?afid=${afid}`
    }
    if (plid) {
      url += `${url.includes("?") ? "&" : "?"}plid=${plid}`
    }
    if (afcd) {
      url += `${url.includes("?") ? "&" : "?"}afcd=${dateTimeToAPI(afcd)}`
    }

    const { data } = await api.get<IProductPrice>(url)
    if (data) {
      return data
    }

    return {
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error) {
    const e = getResponseError(error)
    return {
      code: 2,
      error: e,
    }
  }
}

const sendFacebookCheckout = async ({
  data,
}: {
  data: any
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      "/ProductPixel/facebook/checkout",
      data
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const sendFacebookPurchase = async ({
  data,
}: {
  data: any
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      "/ProductPixel/facebook/purchase",
      data
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const sendFacebookAddPaymentInfo = async ({
  data,
}: {
  data: any
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post(
      "/ProductPixel/facebook/addPaymentInfo",
      data
    )
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const insertProductLinkPageAccess = async ({
  data,
}: {
  data: TProductLinkPageAccess
}): Promise<IResponse> => {
  try {
    const { data: response } = await api.post("/Product/link/access", data)
    if (response) {
      return {
        success: true,
        code: 0,
        error: "",
        data: response,
      }
    }

    return {
      success: false,
      code: 1,
      error: "Erro inesperado do servidor",
    }
  } catch (error: any) {
    const e = getResponseError(error)
    return {
      success: false,
      code: 2,
      error: e,
    }
  }
}

const ProductController = {
  getAll,
  getList,
  get,
  insert,
  update,
  insertPrice,
  updatePrice,
  deletePrice,
  getPaymentByPrice,
  getPaymentByPriceTest,
  getPricesByProduct,
  sendFacebookCheckout,
  sendFacebookPurchase,
  sendFacebookAddPaymentInfo,
  getPriceAffiliatedByCode,
  insertProductLinkPageAccess,
}

export default ProductController
