import { TCountry } from "../types/Country";

const countryBrazil: TCountry = {
    code: "BR",
    name: "Brasil",
}

const countryArgentina: TCountry = {
    code: "AR",
    name: "Argentina",
}

const countryColombia: TCountry = {
    code: "CO",
    name: "Colombia",
}

const countryUnitedStates: TCountry = {
    code: "US",
    name: "United States",
}

const countryPeru: TCountry = {
    code: "PE",
    name: "Peru",
}

const countryAll = [
    countryBrazil,
    countryArgentina,
    countryColombia,
    countryUnitedStates,
    countryPeru,
];

const countryValids = [
    countryBrazil,
];

const CountryFunctions = {
    countryBrazil,
    countryArgentina,
    countryColombia,
    countryUnitedStates,
    countryPeru,
    countryAll,
    countryValids,
}

export default CountryFunctions;