import { Control, UseFormWatch } from "react-hook-form"
import { TSale } from "../../core/types/Sale"
import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutAddressDefault from "./Layouts/LayoutDefault/CheckoutAddressDefault"
import CheckoutAddress2 from "./Layouts/Layout2/CheckoutAddress2"
import React from "react"
import CheckoutAddress3 from "./Layouts/Layout3/CheckoutAddress3"
import CheckoutAddress4 from "./Layouts/Layout4/CheckoutAddress4"
import CheckoutAddress5 from "./Layouts/Layout5/CheckoutAddress5"

export interface ICheckoutAddress {
  layout?: TCheckoutLayout
  hideCheckoutAddress?: boolean
  control: Control<TSale, any>
  loading: boolean
  searchedZipCode: boolean
  watch: UseFormWatch<TSale>
  saveLostSale: () => void
  clearErrors: () => void
  searchZipCode: (loading: boolean, zipCode: string) => void
  handleAddresNumberKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void
  handleAddresComplementKeyDown: (
    e: React.KeyboardEvent<HTMLDivElement>
  ) => void
  onSendPixel: () => void
}

const CheckoutAddress = ({
  layout,
  hideCheckoutAddress,
  control,
  loading,
  searchedZipCode,
  watch,
  saveLostSale,
  clearErrors,
  searchZipCode,
  handleAddresNumberKeyDown,
  handleAddresComplementKeyDown,
  onSendPixel,
}: ICheckoutAddress) => {
  if (hideCheckoutAddress === true) {
    return <></>
  }

  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutAddressDefault
          control={control}
          loading={loading}
          searchedZipCode={searchedZipCode}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutAddress2
          control={control}
          loading={loading}
          searchedZipCode={searchedZipCode}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutAddress3
          control={control}
          loading={loading}
          searchedZipCode={searchedZipCode}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          onSendPixel={onSendPixel}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutAddress4
          control={control}
          loading={loading}
          searchedZipCode={searchedZipCode}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          onSendPixel={onSendPixel}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutAddress5
          control={control}
          loading={loading}
          searchedZipCode={searchedZipCode}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
          searchZipCode={searchZipCode}
          handleAddresNumberKeyDown={handleAddresNumberKeyDown}
          handleAddresComplementKeyDown={handleAddresComplementKeyDown}
          onSendPixel={onSendPixel}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutAddress
