import { Breakpoint, Typography } from "@mui/material"
import ContentDialog from "./ContentDialog"
import React from "react"

export interface IInfoDialogProps {
  open: boolean
  title?: string
  text: string
  onClose: () => void
  labelPrositive?: string
  onPositive?: () => Promise<boolean>
  maxWidth?: false | Breakpoint | undefined
  dataCyButton?: string
}

export default function InfoDialog(props: IInfoDialogProps) {
  const {
    open,
    title,
    text,
    onClose,
    labelPrositive,
    onPositive,
    maxWidth,
    dataCyButton,
  } = props

  return (
    <ContentDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth || "sm"}
      hasCancel={false}
      labelPrositive={labelPrositive}
      onPositive={onPositive}
      title={title}
    >
      <Typography>{text}</Typography>
    </ContentDialog>
  )
}
