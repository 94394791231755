import { Stack } from "@mui/material";
import { Control, UseFormWatch } from "react-hook-form";
import { TSale } from "../../../../core/types/Sale";
import TextFieldRequired from "../../../Inputs/TextFieldRequired";
import Validations from "../../../../core/functions/validations";
import { numberOnly } from "../../../../masks/masks";
import React from "react";

export interface ICheckoutClientDefault {
    control: Control<TSale, any>;
    watch: UseFormWatch<TSale>;
    saveLostSale: () => void;
    clearErrors: () => void;
}

const CheckoutClientDefault = ({ control, watch, saveLostSale, clearErrors }: ICheckoutClientDefault) => {
    const emailValue = watch("email");

    return (
        <Stack direction="column" spacing={3}>
            <TextFieldRequired
                control={control}
                fieldName="name"
                label="Nome Completo"
                placeholder="Digite seu nome completo"
                onFieldExit={saveLostSale}
                clearErrors={clearErrors}
            />
            <TextFieldRequired
                control={control}
                fieldName="email"
                label="E-mail"
                placeholder="Digite seu email para receber a compra"
                onFieldExit={saveLostSale}
                clearErrors={clearErrors}
                validate={(value) => (!value) || (value == '') ? true : Validations.email(value)}
            />
            <TextFieldRequired
                control={control}
                fieldName="emailConfirm"
                label="Confirmação do E-mail"
                placeholder="Confirme seu email digitado anteriormente"
                clearErrors={clearErrors}
                validate={(value) => (!value) || (value == '') ? true : (Validations.email(value)) && (value === emailValue)}
                validateLabel={!Validations.email(watch("emailConfirm")) ? "Confirmação do E-mail inválido" : "E-mail não confere com a confirmação digitada"}
            />
            <Stack direction={"row"} spacing={2}>
                <TextFieldRequired
                    control={control}
                    fieldName="phone"
                    label="Celular"
                    placeholder="(99) 99999-9999"
                    inputProps={{ inputMode: "numeric", maxLength: 15 }}
                    onFieldExit={saveLostSale}
                    clearErrors={clearErrors}
                    validate={(value) => (!value) || (value == '') ? true : numberOnly(value).length === 11}
                />
                <TextFieldRequired
                    control={control}
                    fieldName="document"
                    label="CPF"
                    placeholder="999.999.999-99"
                    inputProps={{ inputMode: "numeric", maxLength: 14 }}
                    clearErrors={clearErrors}
                    validate={(value) => (!value) || (value == '') ? true : Validations.CPF(value)}
                />
            </Stack>
        </Stack>
    );
}

export default CheckoutClientDefault;