import {
  Stack,
  MenuItem,
  Box,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Typography,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { TSale } from "../../../../../core/types/Sale";
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../../core/types/Product";
import { CreditCard } from "../../../../../core/types/CreditCard";
import NumberFunctions from "../../../../../core/functions/NumberFunctions";
import { creditsCards } from "../../../../../core/constants";
import TextFieldRequired from "../../../../Inputs/TextFieldRequired";
import SelectRequired from "../../../../Inputs/SelectRequired";
import { primaryColor } from "../../../../../App";

export interface ICreditCardPaymentPrice5Props {
  visible: boolean;
  control: Control<TSale, any>;
  errors: FieldErrors<TSale>;
  data?: IProductPrice;
  watch: UseFormWatch<TSale>;
  setValue: UseFormSetValue<TSale>;
  installments: IProductPricePaymentInstallment[] | undefined;
  clearErrors: UseFormClearErrors<TSale>;
}

const months = [
  { key: "01", label: "01" },
  { key: "02", label: "02" },
  { key: "03", label: "03" },
  { key: "04", label: "04" },
  { key: "05", label: "05" },
  { key: "06", label: "06" },
  { key: "07", label: "07" },
  { key: "08", label: "08" },
  { key: "09", label: "09" },
  { key: "10", label: "10" },
  { key: "11", label: "11" },
  { key: "12", label: "12" },
];

const CreditCardPaymentPrice5 = (props: ICreditCardPaymentPrice5Props) => {
  const {
    visible,
    control,
    errors,
    data,
    watch,
    setValue,
    installments,
    clearErrors,
  } = props;
  const { register } = control;

  const [creditCard, setCreditCard] = useState<CreditCard | undefined>();
  const [formattedDate, setFormattedDate] = useState("");

  const paymentCardNumber = watch("payment.cardNumber");
  const paymentCardCVV = watch("payment.cardCCV");
  const month = watch("payment.cardExpiresMonth");
  const year = watch("payment.cardExpiresYear");

  const list = installments?.sort((a, b) =>
    a.installmentNumber > b.installmentNumber ? -1 : 1
  );
  const itensMenu =
    list?.map((x) => ({
      key: x.installmentNumber,
      label: `${x.installmentNumber}x de ${NumberFunctions.formatMoneyDefault(
        x.value
      )}`,
    })) || [];

  useEffect(() => {
    var exists = false;
    creditsCards.map((cc) => {
      if (cc.compare(paymentCardNumber)) {
        setCreditCard(cc);
        exists = true;
      }
      return true;
    });
    if (!exists) {
      setCreditCard(undefined);
    }
  }, [paymentCardNumber]);

  useEffect(() => {
    if ((paymentCardCVV || "").length === 4) {
      // comentado pois foi solicitado a alteração para abrir automático as parcelas
      // depois foi pedido para remover, caso seja necessário voltar descomentar
      //handleOpenInstallment();
      document.getElementById("buttonCommit")?.focus();
    }
  }, [paymentCardCVV]);

  const currentYear = new Date().getFullYear();
  const years = Array.from(new Array(10), (val, index) => ({
    key: `${currentYear + index}`,
    label: `${currentYear + index}`,
  }));

  useEffect(() => {
    const formatDate = (month: number, year: number) => {
      const formattedMonth = month.toString().padStart(2, "0");
      const formattedYear = year.toString().slice(-2).padStart(2, "0");
      return `${formattedMonth}/${formattedYear}`;
    };

    const formatted = formatDate(month || 0, year || 0);

    setFormattedDate(formatted);

    setValue("payment.cardValidate", formatted);
  }, [month, year]);

  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down("md")
  );

  return (
    <Box sx={{ display: visible ? "block" : "none" }}>
      <Paper elevation={0}>
        <Stack direction="column" spacing={2}>
          <TextFieldRequired
            size="small"
            variant="labeled"
            control={control}
            fieldName="payment.cardNumber"
            label="Número do cartão"
            placeholder="Digite somente números"
            inputProps={{
              inputMode: "numeric",
              maxLength: 19,
              "data-cy": "cardNumber",
              "data-testid": "cardNumber",
            }}
            dataCyErrorLabel="cardNumberError"
            backgroundColorTitle="#F2F2FF"
            clearErrors={clearErrors}
            required={visible}
            startAdornment={
              <Box sx={{ marginRight: 1 }}>
                <img
                  alt="name-info"
                  src="/assets/icons/payments/credit-card-gray.svg"
                />
              </Box>
            }
            endAdornment={
              creditCard && (
                <img
                  src={creditCard.getImageUrl()}
                  alt="credit card"
                  style={{ height: 30, objectFit: "contain" }}
                />
              )
            }
          />
          <TextFieldRequired
            size="small"
            variant="labeled"
            control={control}
            fieldName="payment.cardName"
            label="Nome do titular"
            placeholder="Nome como está escrito no cartão"
            clearErrors={clearErrors}
            required={visible}
            inputProps={{
              "data-cy": "cardName",
              "data-testid": "cardName",
            }}
            dataCyErrorLabel="cardNameError"
            backgroundColorTitle="#F2F2FF"
            startAdornment={
              <Box sx={{ marginRight: 1 }}>
                <img
                  alt="name-info"
                  src="/assets/icons/customer/nameComplete.svg"
                />
              </Box>
            }
          />
          <Stack direction="row" spacing={1} sx={{ width: "100%" }}>
            <FormControl
              sx={{ width: isSmallScreen ? 190 : "100%" }}
              size="small"
              variant="outlined"
            >
              <Typography
                sx={{ marginBottom: 1.5 }}
                fontSize="14px"
                fontWeight={500}
                lineHeight="18px"
                color="black"
              >
                Mês
              </Typography>
              <Select
                id="payment-month"
                value={month}
                {...register("payment.cardExpiresMonth", {
                  required: visible ? "Campo Mês obrigatório" : false,
                })}
                sx={{
                  backgroundColor: "white",
                  height: "46px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: errors.payment?.cardExpiresMonth
                      ? "2px solid red"
                      : "2px solid #BCC1FF",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${primaryColor}`,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: errors.payment?.cardExpiresMonth
                      ? "2px solid red"
                      : "1px solid #BCC1FF",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "#7E86A0 !important",
                  },
                }}
              >
                {months.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.payment?.cardExpiresMonth && (
                <Typography variant="overline" color="red">
                  {errors.payment.cardExpiresMonth.message}
                </Typography>
              )}
            </FormControl>

            <FormControl
              sx={{ width: isSmallScreen ? 190 : "100%" }}
              size="small"
              variant="outlined"
            >
              <Typography
                sx={{ marginBottom: 1.5 }}
                fontSize="14px"
                fontWeight={500}
                lineHeight="18px"
                color="black"
              >
                Ano
              </Typography>
              <Select
                id="payment-year"
                value={year}
                {...register("payment.cardExpiresYear", {
                  required: visible ? "Campo Mês obrigatório" : false,
                })}
                required={visible}
                sx={{
                  backgroundColor: "white",
                  height: "46px",
                  ".MuiOutlinedInput-notchedOutline": {
                    border: errors.payment?.cardExpiresYear
                      ? "2px solid red"
                      : "2px solid #BCC1FF",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: `1px solid ${primaryColor}`,
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: errors.payment?.cardExpiresYear
                      ? "2px solid red"
                      : "1px solid #BCC1FF",
                  },
                  ".MuiSvgIcon-root ": {
                    fill: "#7E86A0 !important",
                  },
                }}
              >
                {years.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
              {errors.payment?.cardExpiresYear && (
                <Typography variant="overline" color="red">
                  {errors.payment.cardExpiresYear.message}
                </Typography>
              )}
            </FormControl>

            <TextFieldRequired
              size="small"
              variant="labeled"
              control={control}
              fieldName="payment.cardCCV"
              label="Cód. Segurança"
              placeholder=""
              inputProps={{
                inputMode: "numeric",
                maxLength: 4,
                "data-cy": "cardCCV",
                "data-testid": "cardCCV",
              }}
              dataCyErrorLabel="cardCCVError"
              backgroundColorTitle="#F2F2FF"
              clearErrors={clearErrors}
              required={visible}
            />
          </Stack>
          <SelectRequired
            size="small"
            variant="labeled"
            control={control}
            fieldName="payment.installment"
            label="Opções de parcelamento"
            required={visible}
            backgroundColorTitle="#F2F2FF"
          >
            {itensMenu.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </SelectRequired>
        </Stack>
      </Paper>
    </Box>
  );
};

export default CreditCardPaymentPrice5;
