import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutButtonCommit2 from "./Layouts/Layout2/CheckoutButtonCommit2"
import CheckoutButtonCommit3 from "./Layouts/Layout3/CheckoutButtonCommit3"
import CheckoutButtonCommit4 from "./Layouts/Layout4/CheckoutButtonCommit4"
import CheckoutButtonCommit5 from "./Layouts/Layout5/CheckoutButtonCommit5"
import CheckoutButtonCommitDefault from "./Layouts/LayoutDefault/CheckoutButtonCommitDefault"

export interface ICheckoutButtonCommit {
  layout?: TCheckoutLayout
}

const CheckoutButtonCommit = ({ layout }: ICheckoutButtonCommit) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return <CheckoutButtonCommitDefault />
    case TCheckoutLayout.Layout2:
      return <CheckoutButtonCommit2 />
    case TCheckoutLayout.Layout3:
      return <CheckoutButtonCommit3 />
    case TCheckoutLayout.Layout4:
      return <CheckoutButtonCommit4 />
    case TCheckoutLayout.Layout5:
      return <CheckoutButtonCommit5 />
    default:
      return <></>
  }
}

export default CheckoutButtonCommit
