import { Stack, Typography } from "@mui/material";
import { blue, teal } from "@mui/material/colors";
import React from "react";

export interface ICheckoutFooter3 {
    tenantName?: string;
    setOpenTermsPurchase: (value: boolean) => void;
}

const CheckoutFooter3 = ({ tenantName, setOpenTermsPurchase }: ICheckoutFooter3) => {
    return (
        <Stack direction="column" spacing={3}>
            {tenantName && (
                <Typography variant="caption" textAlign={"center"}>
                    Nós estamos processando o pagamento em nome de {tenantName ?? ""}
                </Typography>
            )}

            <Stack direction={"column"} alignContent={"center"} alignItems={"center"}>
                <Typography variant="caption" textAlign={"center"}>
                    Esse site é protegido pelo reCAPTCHA do Google
                </Typography>
                <Typography variant="caption" textAlign={"center"}>
                    <a href="https://policies.google.com/privacy" style={{ color: blue[300] }}>
                        Política de Privacidade
                    </a>{" "}
                    e{" "}
                    <a href="https://policies.google.com/terms" style={{ color: blue[300] }}>
                        Termos de Serviço
                    </a>
                </Typography>
                <Typography variant="caption" textAlign={"center"}>
                    *Parcelamento com acréscimo de 2.99% a.m
                </Typography>
                <Typography variant="caption" textAlign={"center"} style={{ display: "inline-block" }}>
                    Ao prosseguir você concorda com os{" "}
                    <Typography
                        variant="caption"
                        color={blue[300]}
                        sx={{ cursor: "pointer", textDecoration: "underline" }}
                        style={{ display: "inline-block" }}
                        onClick={() => setOpenTermsPurchase(true)}
                    >
                        Termos de Compra
                    </Typography>
                </Typography>
            </Stack>
            <Typography variant="caption" textAlign={"center"}>
                Celetus &copy; 2024 - Todos os direitos reservados.
            </Typography>
        </Stack>
    );
};

export default CheckoutFooter3;
