import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import { TCheckoutLayout } from "../../core/types/Checkout"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../core/types/Product"
import CheckoutPaymentsDefault from "./Layouts/LayoutDefault/CheckoutPaymentsDefault"
import { TSale } from "../../core/types/Sale"
import CheckoutPayments2 from "./Layouts/Layout2/CheckoutPayments2"
import React from "react"
import CheckoutPayments3 from "./Layouts/Layout3/CheckoutPayments3"
import CheckoutPayments4 from "./Layouts/Layout4/CheckoutPayments4"
import CheckoutPayments5 from "./Layouts/Layout5/CheckoutPayments5"

export interface ICheckoutPayments {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  loadingVoucher: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
  onApplyVoucher: () => void
  onRemoveVoucher: () => void
}

const CheckoutPayments = ({
  layout,
  productPriceId,
  loading,
  loadingVoucher,
  data,
  control,
  watch,
  setValue,
  errors,
  installments,
  clearErrors,
  onApplyVoucher,
  onRemoveVoucher,
}: ICheckoutPayments) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutPaymentsDefault
          productPriceId={productPriceId}
          data={data}
          loading={loading}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          installments={installments}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutPayments2
          productPriceId={productPriceId}
          data={data}
          loading={loading}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          installments={installments}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutPayments3
          layout={layout}
          productPriceId={productPriceId}
          data={data}
          loading={loading}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          installments={installments}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutPayments4
          layout={layout}
          productPriceId={productPriceId}
          data={data}
          loading={loading}
          loadingVoucher={loadingVoucher}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          installments={installments}
          clearErrors={clearErrors}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutPayments5
          layout={layout}
          productPriceId={productPriceId}
          data={data}
          loading={loading}
          loadingVoucher={loadingVoucher}
          control={control}
          setValue={setValue}
          watch={watch}
          errors={errors}
          installments={installments}
          clearErrors={clearErrors}
          onApplyVoucher={onApplyVoucher}
          onRemoveVoucher={onRemoveVoucher}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutPayments
