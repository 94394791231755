import { Button, Stack, Typography } from "@mui/material";
import GppGoodIcon from '@mui/icons-material/GppGoodOutlined';

const CheckoutButtonCommit3 = () => {
    return (
        <Stack
            direction={"column"}
            alignContent={"center"}
            alignItems={"center"}
            sx={{ width: "100%", pt: 2 }}
        >
            <Button
                id="buttonCommit"
                type="submit"
                variant="contained"
                sx={{ pl: 8, pr: 8, height: 50, textTransform: 'none' }}
                startIcon={<GppGoodIcon />}
                data-testid="buttonCommit"
            >
                <Typography fontWeight={500}>Pagar e Receber Agora</Typography>
            </Button>
        </Stack>
    );
}

export default CheckoutButtonCommit3;