import { Box, Stack, Typography } from "@mui/material";

const BoletoPaymentPrice5 = ({ visible }: { visible: boolean }) => {
  return (
    <Stack
      direction={"column"}
      spacing={2}
      p={0.5}
      sx={{ display: visible ? undefined : "none" }}
    >
      <Box>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/checkBlue.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          Pagamentos com Boleto Bancário levam até 3 dias úteis para serem
          compensados e então terem os produtos liberados
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/checkBlue.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          Atente-se ao vencimento do boleto. Você pode pagar o boleto em
          qualquer banco ou casa lotérica até o dia do vencimento
        </Typography>
      </Box>

      <Box>
        <Typography
          fontSize="14px"
          lineHeight="18px"
          fontWeight={400}
          color="rgba(112, 114, 117, 1)"
          sx={{ display: "flex", alignItems: "center", gap: "8px" }}
        >
          <img
            src="/assets/icons/payments/checkBlue.svg"
            alt=""
            style={{ height: "24px", width: "24px", objectFit: "contain" }}
          />
          Depois do pagamento, verifique seu e-mail para receber os dados de
          acesso ao produto (verifique também a caixa de SPAM)
        </Typography>
      </Box>
    </Stack>
  );
};

export default BoletoPaymentPrice5;
