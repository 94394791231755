import { useContext } from "react"
import { TCheckoutContext } from "../types/Checkout"
import { CheckoutContext } from "../contexts/CheckoutContext"

export const useCheckoutContext = (): TCheckoutContext => {
  const context = useContext(CheckoutContext)
  if (context === undefined) {
    throw new Error(
      "useCheckoutContext deve ser usado dentro de um StorageProvider"
    )
  }
  return context
}
