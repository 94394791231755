import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const LoadingBackdrop = ({ open }: { open: boolean }) => {
    return (
        <Backdrop
            sx={{
                bgColor: "#3333",
                color: "#fff",
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}

export default LoadingBackdrop;