import { CreditCard, CreditCardType } from "../types/CreditCard";
import { startNumberAmerican } from "../constants";
import CreditCards from "../functions/creditCards";

export class CreditCardAmerican implements CreditCard {
    getType(): CreditCardType {
        return CreditCardType.American;
    }
    compare(number?: string): boolean {
        return CreditCards.compare(startNumberAmerican, number);
    }
    getImageUrl() {
        return `${process.env.REACT_APP_PAY_HOST}/assets/creditcards/american.png`;
    }
}