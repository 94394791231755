import { Stack } from "@mui/material"

interface IStackOrFormProps {
  noValidate?: boolean | undefined
  component?: "form"
  onSubmit?: React.FormEventHandler<HTMLFormElement> | undefined
  children: React.ReactNode
}

const StackOrForm = ({
  noValidate,
  component,
  children,
  onSubmit,
}: IStackOrFormProps) => {
  if (component === "form") {
    return (
      <Stack component={component} noValidate={noValidate} onSubmit={onSubmit}>
        {children}
      </Stack>
    )
  }

  return <>{children}</>
}

export default StackOrForm
