import { Control, UseFormWatch } from "react-hook-form"
import { TSale } from "../../core/types/Sale"
import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutClientDefault from "./Layouts/LayoutDefault/CheckoutClientDefault"
import CheckoutClient2 from "./Layouts/Layout2/CheckoutClient2"
import React from "react"
import CheckoutClient3 from "./Layouts/Layout3/CheckoutClient3"
import CheckoutClient4 from "./Layouts/Layout4/CheckoutClient4"
import CheckoutClient5 from "./Layouts/Layout5/CheckoutClient5"

export interface ICheckoutClient {
  layout?: TCheckoutLayout
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  saveLostSale: () => void
  clearErrors: () => void
}

const CheckoutClient = ({
  layout,
  control,
  watch,
  saveLostSale,
  clearErrors,
}: ICheckoutClient) => {
  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutClientDefault
          control={control}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutClient2
          control={control}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutClient3
          control={control}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutClient4
          control={control}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutClient5
          control={control}
          watch={watch}
          saveLostSale={saveLostSale}
          clearErrors={clearErrors}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutClient
