import {
  Control,
  FieldErrors,
  UseFormClearErrors,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form"
import {
  IProductPrice,
  IProductPricePaymentInstallment,
} from "../../../../core/types/Product"
import PaymentsByProduct from "../../../../pages/Checkout/Payment"
import { TSale } from "../../../../core/types/Sale"
import React from "react"
import { Box, Stack, Typography } from "@mui/material"
import { TCheckoutLayout } from "../../../../core/types/Checkout"

export interface ICheckoutPayments3 {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

const CheckoutPayments3 = ({
  layout,
  productPriceId,
  loading,
  data,
  control,
  watch,
  setValue,
  errors,
  installments,
  clearErrors,
}: ICheckoutPayments3) => {
  return (
    <Box>
      <Stack direction="column" alignContent="center" alignItems="center">
        <Typography variant="overline" gutterBottom>
          Selecione o método de pagamento
        </Typography>
      </Stack>
      <PaymentsByProduct
        layout={layout}
        productPriceId={productPriceId}
        data={data}
        loading={loading}
        control={control}
        setValue={setValue}
        watch={watch}
        errors={errors}
        installments={installments}
        clearErrors={clearErrors}
      />
    </Box>
  )
}

export default CheckoutPayments3
