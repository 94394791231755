import { Alert, Box, Button, Snackbar, Stack, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import Hosts from "../../../../core/functions/HostFunctions";
import React from "react";
import { useQuery } from "../../../../components/Query/useQuery";

export interface IBoletoSuccessProps {
  barCode: string;
  barCodeUrl: string;
  pdfUrl: string;
  redirectUrl?: string | null;
}

export const BoletoSuccess = ({
  barCode,
  barCodeUrl,
  pdfUrl,
  redirectUrl,
}: IBoletoSuccessProps) => {
  const [copied, setCopied] = useState(false);
  const [redirectSeconds, setRedirectSeconds] = useState(15);
  const [isRedirect, setIsRedirect] = useState(false);

  const query = useQuery();

  const handleClick = async () => {
    await Hosts.copyTextToClipboard(barCode);
    setCopied(true);
  };

  useEffect(() => {
    if ((redirectUrl ?? "") !== "") {
      if (isRedirect) {
        if (redirectSeconds === 0 && redirectUrl) {
          window.location.href = Hosts.getUrlRedirect(redirectUrl);
          return;
        }

        setTimeout(() => {
          setRedirectSeconds((redirectSeconds) => redirectSeconds - 1);
        }, 1000);
      }

      const redirect = query.get("redirect");
      if (redirect !== "no") {
        setIsRedirect(true);
      }
    }
  }, [isRedirect, redirectSeconds, redirectUrl]);

  return (
    <Stack direction={"column"} spacing={1}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="body2" sx={{ mb: "0.01rem" }}>
            Estamos aguardando a confirmação do pagamento do boleto abaixo.
          </Typography>
          <Typography variant="body2" sx={{ mb: "1.1rem" }}>
            Imprima o boleto e e pague no banco ou pela internet utilizando o
            código de barras.
          </Typography>
        </Box>
        {/* <img src={`${barCodeUrl}?format=img`} alt="qrCode" /> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "1.5rem",
          }}
        >
          <Button
            variant="contained"
            onClick={handleClick}
            startIcon={<ContentCopyIcon />}
          >
            Copiar código
          </Button>
          <Button
            component="button"
            variant="contained"
            color="secondary"
            onClick={() => window.open(pdfUrl, "_blank")}
          >
            Imprimir Boleto
          </Button>
        </Box>

        {isRedirect && (
          <Stack
            direction="column"
            spacing={3}
            alignContent="center"
            alignItems="center"
            sx={{ pt: 8 }}
          >
            <Typography variant="body1" sx={{ mt: 2 }}>
              Você será redirecionado para a pagina de confirmação em{" "}
              {redirectSeconds} segundos, aguarde...
            </Typography>
          </Stack>
        )}
      </Box>

      <Snackbar
        open={copied}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        message="Código copiado"
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setCopied(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          Código copiado!
        </Alert>
      </Snackbar>
    </Stack>
  );
};
