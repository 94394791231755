import { Container, Typography } from "@mui/material"
import React from "react";

const HomePage: React.FC = () => {

    return (
        <Container>
            <Typography variant="h4">Celetus</Typography>
        </Container>
    );
}

export default HomePage;