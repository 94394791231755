import { Box, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useRef, useState } from "react"
import {
  oneHourInMilliseconds,
  oneMinuteInMilliseconds,
  oneSecondInMilleseconds,
} from "../../../core/functions/dateTime"
import TimerIcon from "@mui/icons-material/Timer"

export interface IStopWatchWidgetProps {
  initialTime: number
  text: string
  textFinished: string
  backgroundColor?: string
  color?: string
  isFixedTop: boolean
}

const StopWatchWidget = ({
  initialTime,
  text,
  textFinished,
  backgroundColor,
  color,
  isFixedTop,
}: IStopWatchWidgetProps) => {
  initialTime ??= 15 * oneMinuteInMilliseconds
  const [time, setTime] = useState(initialTime)
  const [finished, setFinished] = useState(false)

  const initialTimeOld = useRef(0)

  const getHours = () => {
    return ("0" + Math.floor((time / 3600000) % 60)).slice(-2)
  }

  const getMinutes = () => {
    return ("0" + Math.floor((time / 60000) % 60)).slice(-2)
  }

  const getSeconds = () => {
    return ("0" + Math.floor((time / 1000) % 60)).slice(-2)
  }

  const updateTime = useCallback(() => {
    setTime((t) => t - (t <= 0 ? 0 : oneSecondInMilleseconds))
  }, [setTime])

  useEffect(() => {
    if (time <= 1) {
      setFinished(true)
    }
  }, [time])

  useEffect(() => {
    const interval = setInterval(updateTime, oneSecondInMilleseconds)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (initialTime !== initialTimeOld.current) {
      initialTimeOld.current = initialTime
      setTime(initialTime)
      if (finished) {
        setFinished(false)
      }
    }
  }, [initialTime])

  const TypographyStopWatch = ({
    children,
  }: {
    children: JSX.Element | string
  }) => {
    return (
      <Typography variant="h4" fontWeight={500}>
        {children}
      </Typography>
    )
  }

  return (
    <Box
      position={isFixedTop ? "fixed" : undefined}
      left={isFixedTop ? "0px" : undefined}
      right={isFixedTop ? "0px" : undefined}
      top={isFixedTop ? "0px" : undefined}
      zIndex={isFixedTop ? 1000 : undefined}
    >
      <Stack
        sx={{
          py: 2,
          width: "100%",
          backgroundColor: backgroundColor ?? "#F44747",
          color: color ?? "white",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          sx={{ width: "100%" }}
        >
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            alignContent="center"
          >
            <Stack direction="row" spacing={1}>
              {initialTime > oneHourInMilliseconds && (
                <TypographyStopWatch>{getHours()}</TypographyStopWatch>
              )}
              {initialTime > oneHourInMilliseconds && (
                <TypographyStopWatch>:</TypographyStopWatch>
              )}
              <TypographyStopWatch>{getMinutes()}</TypographyStopWatch>
              <TypographyStopWatch>:</TypographyStopWatch>
              <TypographyStopWatch>{getSeconds()}</TypographyStopWatch>
            </Stack>
            <TimerIcon sx={{ fontSize: "2rem" }} />
          </Stack>
          {!finished && <Typography>{text}</Typography>}
          {finished && <Typography>{textFinished}</Typography>}
        </Stack>
      </Stack>
    </Box>
  )
}

export default StopWatchWidget
