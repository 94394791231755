import TagFunctions from "./TagFunctions"

const kondutoEventos = `var __kdt = __kdt || [];
    __kdt.push({"public_key": "${process.env.REACT_APP_KONDUTO_KEY}"});
    (function() {
        var kdt = document.createElement('script');
        kdt.id = 'kdtjs'; kdt.type = 'text/javascript';
        kdt.async = true; kdt.src = 'https://i.k-analytix.com/k.js';
        var s = document.getElementsByTagName('body')[0];
        s.parentNode.insertBefore(kdt, s);
    })();`;

const addTagMetaCheckout = () => {
    return TagFunctions.addMetaHeader("kdt:page", "checkout");
}

const addTagMetaCheckoutProduct = (productPriceCode: string, productName: string) => {
    const pageProduct = TagFunctions.addMetaHeader("kdt:page", "product");
    const product = TagFunctions.addMetaHeader("kdt:product", `sku=${productPriceCode}, name=${productName}`);
    return [pageProduct, product];
}

const addTagEventos = () => {
    return TagFunctions.addScriptHeader(kondutoEventos, "text/javascript");
}

const KondutoFunctions = {
    addTagMetaCheckout,
    addTagMetaCheckoutProduct,
    addTagEventos,
}

export default KondutoFunctions;