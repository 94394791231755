import { Box, Button, Typography } from "@mui/material"
import ContentDialog from "../../../components/Dialogs/ContentDialog"
import { TClickAction, TPopupConfig } from "../../../core/types/CustomCheckout"
import HostFunctions, { payUrl } from "../../../core/functions/HostFunctions"

interface TExitPopupDialog {
  isExitPopup: boolean
  exitPopup?: TPopupConfig
  setIsExitPopup: (value: boolean) => void
  applyVoucher: (value: string) => void
}

const ExitPopupDialog = ({
  isExitPopup,
  exitPopup,
  setIsExitPopup,
  applyVoucher,
}: TExitPopupDialog) => {
  function handleChange(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void {
    if (exitPopup?.actionClick && exitPopup?.actionValue) {
      if (exitPopup.actionClick === TClickAction.redirectUrl) {
        window.location.href = HostFunctions.getUrlRedirect(
          `${payUrl}/${exitPopup.actionValue}`
        )
      } else if (exitPopup.actionClick === TClickAction.discount) {
        applyVoucher(exitPopup.actionValue)
      }
    }
    setIsExitPopup(false)
  }

  return (
    <ContentDialog
      title="Oferta"
      open={isExitPopup}
      onClose={() => setIsExitPopup(false)}
      isTitle={false}
      paddingContent="0px"
      maxWidth="md"
      hideButtons
    >
      {exitPopup?.imageUrl && exitPopup.imageUrl !== "" && (
        <img
          src={exitPopup?.imageUrl}
          style={{ width: "100%", objectFit: "contain" }}
          loading="lazy"
        />
      )}
      <Box width="100%" sx={{ p: 2 }}>
        {exitPopup?.mainText && exitPopup.mainText !== "" && (
          <Typography
            gutterBottom
            fontSize="16px"
            fontWeight={700}
            lineHeight="20px"
          >
            {exitPopup?.mainText}
          </Typography>
        )}
        {exitPopup?.auxText && exitPopup.auxText !== "" && (
          <Typography
            gutterBottom
            fontSize="14px"
            fontWeight={400}
            lineHeight="18px"
          >
            {exitPopup?.auxText}
          </Typography>
        )}
        <Button
          fullWidth
          size="large"
          variant="contained"
          onClick={handleChange}
          sx={{ mt: 1 }}
        >
          {exitPopup?.textButton}
        </Button>
      </Box>
    </ContentDialog>
  )
}

export default ExitPopupDialog
