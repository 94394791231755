import {
  Box,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'
import { styled } from '@mui/material/styles'

export interface IErrorSnackbarProps {
  open: boolean
  children: string | JSX.Element
  onClose: () => void
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#DCDDDE',
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: '#EC5040',
  },
}))

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return <BorderLinearProgress variant="determinate" {...props} />
}

const ErrorSnackbar = ({ open, onClose, children }: IErrorSnackbarProps) => {
  const [progress, setProgress] = React.useState(100)
  let timer: NodeJS.Timer

  const autoHideDuration = 6000

  React.useEffect(() => {
    return () => {
      if (timer) clearInterval(timer)
    }
  }, [])

  React.useEffect(() => {
    if (open) {
      if (!timer) {
        timer = setInterval(() => {
          setProgress((prevProgress) => {
            if (prevProgress < 0) {
              clearInterval(timer)
              setTimeout(onClose, 500)
              setTimeout(() => setProgress(100), 1000)
              return 0
            }
            return prevProgress - 100 / (autoHideDuration / 50)
          })
        }, 50)
      }
    }
  }, [open])

  return (
    <Snackbar
      open={open}
      //autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Box sx={{ backgroundColor: 'white', borderRadius: '4px' }}>
        <Stack direction="row" spacing={1} sx={{ p: 1 }} alignItems="center">
          <Box width="40px" height="40px">
            <img src="/assets/messages/error.svg" alt="error" loading="lazy" />
          </Box>
          <Box>
            <Typography
              fontWeight={600}
              fontSize="16px"
              lineHeight="20px"
              color="#343948"
            >
              Erro!
            </Typography>
            <Typography
              fontWeight={400}
              fontSize="12px"
              lineHeight="16px"
              color="#444B5F"
            >
              {children}
            </Typography>
          </Box>
          <IconButton size="small" onClick={onClose}>
            <img src="/assets/messages/close.svg" alt="close" loading="lazy" />
          </IconButton>
        </Stack>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </Snackbar>
  )
}

export default ErrorSnackbar
