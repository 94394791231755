import { IOrderBump } from "../../core/types/OrderBump"
import { TCheckoutLayout } from "../../core/types/Checkout"
import CheckoutOrderBumpsDefault from "./Layouts/LayoutDefault/CheckoutOrderBumpsDefault"
import CheckoutOrderBumps2 from "./Layouts/Layout2/CheckoutOrderBumps2"
import React from "react"
import CheckoutOrderBumps3 from "./Layouts/Layout3/CheckoutOrderBumps3"
import CheckoutOrderBumps4 from "./Layouts/Layout4/CheckoutOrderBumps4"
import CheckoutOrderBumps5 from "./Layouts/Layout5/CheckoutOrderBumps5"

export interface ICheckoutOrderBumps {
  layout?: TCheckoutLayout
  paymentInstallment: number
  paymentMethodType: number
  orderBumps?: IOrderBump[]
  orderBumpChange: (orderBump: IOrderBump) => Promise<void>
}

const CheckoutOrderBumps = ({
  layout,
  paymentInstallment,
  paymentMethodType,
  orderBumps,
  orderBumpChange,
}: ICheckoutOrderBumps) => {
  if ((orderBumps?.length ?? 0) <= 0) {
    return <></>
  }

  switch (layout) {
    case TCheckoutLayout.Default:
      return (
        <CheckoutOrderBumpsDefault
          orderBumps={orderBumps}
          orderBumpChange={orderBumpChange}
        />
      )
    case TCheckoutLayout.Layout2:
      return (
        <CheckoutOrderBumps2
          orderBumps={orderBumps}
          orderBumpChange={orderBumpChange}
        />
      )
    case TCheckoutLayout.Layout3:
      return (
        <CheckoutOrderBumps3
          key={`orderBumps${orderBumps?.length}`}
          orderBumps={orderBumps}
          orderBumpChange={orderBumpChange}
        />
      )
    case TCheckoutLayout.Layout4:
      return (
        <CheckoutOrderBumps4
          key={`orderBumps${orderBumps?.length}`}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          orderBumps={orderBumps}
          orderBumpChange={orderBumpChange}
        />
      )
    case TCheckoutLayout.Layout5:
      return (
        <CheckoutOrderBumps5
          key={`orderBumps${orderBumps?.length}`}
          paymentInstallment={paymentInstallment}
          paymentMethodType={paymentMethodType}
          orderBumps={orderBumps}
          orderBumpChange={orderBumpChange}
        />
      )
    default:
      return <></>
  }
}

export default CheckoutOrderBumps
