import React from "react";
import { TSaleProduct } from "../../../core/types/Sale";
import OrderItem from "./OrderItem";

export interface IOrderProductProp {
    paymentMethodType: number;
    installmentNumber: number;
    product: TSaleProduct;
}

const OrderProduct = ({ paymentMethodType, installmentNumber, product }: IOrderProductProp) => {

    return (
        <OrderItem
            key={product.id}
            paymentMethodType={paymentMethodType}
            installmentNumber={installmentNumber}
            description={product.productName}
            value={product.firstPurchasePrice || product.value}
            defaultValue={product.value}
            installments={product.installments}
            typePayment={product.typePayment}
            frequency={product.frequency}
        />
    );
}

export default OrderProduct;