import { Box, CircularProgress } from "@mui/material";
import { useState } from "react";
import ImageIcon from "@mui/icons-material/Image";

export interface ICheckoutImageProps {
  alt: string;
  imageUrl?: string;
}

const CheckoutImage = ({ alt, imageUrl }: ICheckoutImageProps) => {
  const [loaded, setLoaded] = useState(
    !imageUrl || imageUrl === "" ? true : false
  );

  const imageStyle = !loaded
    ? { display: "none" }
    : { width: "100%", borderRadius: "8px", margin: 0, padding: 0 };

  const handleImageLoaded = () => {
    setLoaded(true);
  };

  return (
    <Box sx={{ px: { xs: 2, sm: 0 } }}>
      <div
        style={{
          position: "relative",
          width: "100%",
          borderRadius: "8px",
          marginTop: 5,
        }}
      >
        {!loaded && <CircularProgress />}
        {imageUrl && imageUrl !== undefined && (
          <img
            src={imageUrl}
            alt={alt}
            style={{
              width: "100%",
              objectFit: "contain",
              objectPosition: "center",
              borderRadius: "8px",
            }}
            onLoad={handleImageLoaded}
          />
        )}
        {(!imageUrl || imageUrl === "") && (
          <ImageIcon
            sx={{ fontSize: "2rem", color: "silver", objectFit: "cover" }}
          />
        )}
      </div>
    </Box>
  );
};

export default CheckoutImage;
