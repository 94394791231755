import { CircularProgress, Stack } from "@mui/material";
import React, { Fragment } from "react";

export interface IImagePlaceholderProps {
    imageUrl?: string;
    alt: string;
    style?: React.CSSProperties;
}

const ImagePlaceholder = ({ imageUrl, alt, style }: IImagePlaceholderProps) => {
    const [loaded, setLoaded] = React.useState(false);

    const handleImageLoaded = () => {
        setLoaded(true);
    }

    const imageStyle = !loaded ? { display: "none" } : { ...style };

    if (!imageUrl || imageUrl === '') {
        return (<></>);
    }

    return (
        <div>
            {!loaded && <Stack direction="column" alignItems="center" justifyContent="center" sx={{ height: { sm: 400, xs: 200 } }}><CircularProgress /></Stack>}
            <img src={imageUrl} alt={alt} style={imageStyle} onLoad={handleImageLoaded} />
        </div>
    );
}

export default ImagePlaceholder;