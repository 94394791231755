import { Avatar, Box, Card, Stack } from "@mui/material";

export interface CoverImageSecurityItemProps {
    imgSrc: string;
    children: JSX.Element | JSX.Element[];
}

const CoverImageSecurityItem = ({ imgSrc, children }: CoverImageSecurityItemProps) => {
    return (
        <Card elevation={0} sx={{ border: "1px solid", borderColor: "#DCDDDE", borderRadius: "8px", width: "100%" }}>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={1}
                sx={{ p: { xs: "10px", sm: "12px" }, gap: { xs: "2px", sm: "12px" } }}
                //alignItems="center"
            >
                <Avatar sx={{ bgcolor: "#F2F2FF" }}>
                    <img src={imgSrc} alt="" />
                </Avatar>
                <Box width="100%">{children}</Box>
            </Stack>
        </Card>
    );
};

export default CoverImageSecurityItem;
