import { Button, CircularProgress, Stack, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2"
import {
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
  Control,
  UseFormClearErrors,
} from "react-hook-form"
import PaymentIcon from "@mui/icons-material/Paid"
import React from "react"
import CreditCardPaymentPrice from "./CreditCard"
import DebitCardIcon from "@mui/icons-material/CreditCardTwoTone"
import DebitCardPaymentPrice from "./DebitCard"
import PixPaymentPrice from "./Pix"
import BoletoPaymentPrice from "./Boleto"
import { TCheckoutLayout } from "../../../../../core/types/Checkout"
import {
  IProductPrice,
  IProductPricePayment,
  IProductPricePaymentInstallment,
} from "../../../../../core/types/Product"
import { TSale } from "../../../../../core/types/Sale"
import {
  methodTypeBoleto,
  methodTypeCreditCard,
  methodTypeDebitCard,
  methodTypePix,
} from "../../../../../core/constants"

export interface IPaymentsByProduct4Props {
  layout?: TCheckoutLayout
  productPriceId: string
  loading: boolean
  data: IProductPrice | null
  control: Control<TSale, any>
  watch: UseFormWatch<TSale>
  setValue: UseFormSetValue<TSale>
  errors: FieldErrors<TSale>
  installments?: IProductPricePaymentInstallment[]
  clearErrors: UseFormClearErrors<TSale>
}

const PaymentsByProduct4 = (props: IPaymentsByProduct4Props) => {
  const {
    layout,
    loading,
    data,
    setValue,
    control,
    errors,
    watch,
    installments,
    clearErrors,
  } = props

  const maxPaymentLine = 4
  const isMorePayments = (data?.paymentMethods.length ?? 0) > maxPaymentLine

  const [selected, setSelected] = React.useState(0)
  const [openMorePay, setOpenMorePay] = React.useState(!isMorePayments)

  const paymentMethodType = watch("payment.methodType")
  const paymentCardName = watch("payment.cardName")
  const paymentCardValidate = watch("payment.cardValidate")
  const paymentCardCVV = watch("payment.cardCCV")

  React.useEffect(() => {
    if (paymentMethodType !== selected) {
      setSelected(paymentMethodType)
    }
  }, [paymentMethodType, selected])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setOpenMorePay(!openMorePay)
      return
    }

    setSelected(newValue)

    const id = newValue
    setValue("payment.methodType", id)
  }

  const getPaymentMethods = () => {
    return isMorePayments && !openMorePay
      ? data?.paymentMethods.filter((pm, i) => i < maxPaymentLine)
      : data?.paymentMethods
  }

  const getPaymentIcon = (
    payment: IProductPricePayment | undefined,
    isValid: boolean
  ) => {
    if (!payment) {
      return (
        <img
          src={`/assets/icons/payments/plus.svg`}
          alt={openMorePay ? "Menos" : "Mais"}
        />
      )
    }
    switch (payment.methodType) {
      case methodTypeCreditCard:
        return (
          <img
            src={`/assets/icons/payments/credit-card${
              isValid ? "-selected" : ""
            }.svg`}
            alt="Cartão de Crédito"
          />
        )
      case methodTypeDebitCard:
        return <DebitCardIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
      case methodTypePix:
        return (
          <img
            src={`/assets/icons/payments/pix${isValid ? "-selected" : ""}.svg`}
            alt="PIX"
          />
        )
      case methodTypeBoleto:
        return (
          <img
            src={`/assets/icons/payments/barcode${
              isValid ? "-selected" : ""
            }.svg`}
            alt="Boleto"
          />
        )
      default:
        return <PaymentIcon sx={{ color: isValid ? "#1b5e20" : "#424242" }} />
    }
  }

  const getPaymentDescription = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return openMorePay ? "Menos" : "Mais"
    }

    switch (payment.methodType) {
      case methodTypeCreditCard:
        return "Cartão de Crédito"
      case methodTypeDebitCard:
        return "Débito"
      case methodTypePix:
        return "PIX"
      case methodTypeBoleto:
        return "Boleto"
      default:
        return ""
    }
  }

  const isPaymentValid = (payment: IProductPricePayment | undefined) => {
    if (!payment) {
      return false
    }

    var valid = payment.methodType === paymentMethodType

    if (
      valid &&
      (paymentMethodType === methodTypeCreditCard ||
        paymentMethodType === methodTypeDebitCard)
    ) {
      valid =
        paymentCardName !== "" &&
        paymentCardName !== "" &&
        paymentCardValidate !== "" &&
        paymentCardCVV !== ""
    }

    return valid
  }

  const getPaymentItem = (
    payment: IProductPricePayment | undefined,
    index: number
  ) => {
    return (
      <Grid
        key={`pay-${index}`}
        xs={1}
        sm={isMorePayments ? 4 : 16 / (data?.paymentMethods.length ?? 16)}
      >
        <Button
          fullWidth
          id={`buttonPayment${payment?.methodType ?? 0}`}
          variant="outlined"
          sx={{
            p: 1.5,
            height: "56px",
            backgroundColor: "white",
            borderColor: isPaymentValid(payment) ? "#1C52BD" : "#C5C6C9",
            color: "#212121",
            textTransform: "none",
          }}
          onClick={(e) => handleChange(e, payment?.methodType ?? 0)}
          data-testid={`buttonPayment${payment?.methodType ?? 0}`}
        >
          <Stack
            direction="row"
            spacing={1}
            width="100%"
            alignContent="center"
            alignItems="center"
          >
            {getPaymentIcon(payment, isPaymentValid(payment))}
            <Typography textAlign="start" fontSize="14px" lineHeight="18px">
              {getPaymentDescription(payment)}
            </Typography>
          </Stack>
        </Button>
      </Grid>
    )
  }

  return (
    <Stack sx={{ width: "100%" }}>
      {loading && <CircularProgress />}
      {!loading && data !== null && (
        <Stack sx={{ width: "100%" }} spacing={3}>
          <Grid
            container
            spacing={1}
            justifyContent="center"
            style={{ overflowY: "hidden" }}
            columns={{
              xs: 1,
              sm: 16,
            }}
            data-cy="containerPaymentOptions"
          >
            {getPaymentMethods()?.map((payment, index) =>
              getPaymentItem(payment, index)
            )}
            {isMorePayments && getPaymentItem(undefined, -1)}
          </Grid>

          <CreditCardPaymentPrice
            visible={selected === methodTypeCreditCard}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
            installments={installments}
            clearErrors={clearErrors}
          />

          <DebitCardPaymentPrice
            visible={selected === methodTypeDebitCard}
            control={control}
            watch={watch}
            setValue={setValue}
            errors={errors}
            clearErrors={clearErrors}
          />

          <PixPaymentPrice visible={selected === methodTypePix} />

          <BoletoPaymentPrice visible={selected === methodTypeBoleto} />
        </Stack>
      )}
    </Stack>
  )
}

export default PaymentsByProduct4
