import { grey } from "@mui/material/colors";
import { CreditCardAmerican } from "../models/CreditCardAmerican";
import { CreditCardDiners } from "../models/CreditCardDiners";
import { CreditCardDiscover } from "../models/CreditCardDiscover";
import { CreditCardElo } from "../models/CreditCardElo";
import { CreditCardJCB } from "../models/CreditCardJCB";
import { CreditCardMaster } from "../models/CreditCardMaster";
import { CreditCardVisa } from "../models/CreditCardVisa";
import {
  TCustomCheckout,
  TCustomCheckoutComponentItem,
  TCustomCheckoutComponentItemSX,
  TCustomCheckoutComponentType,
} from "../types/CustomCheckout";
import { IOrderChargeStatus } from "../types/Order";

export const isWidget = () => {
  return process.env.REACT_APP_FEATURE_MODE === "widget";
};

export const methodTypeCreditCard = 1;
export const methodTypeDebitCard = 2;
export const methodTypePix = 3;
export const methodTypeBoleto = 4;

export const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const VOUCHER_QUERY_NAMES = [
  "CupomName",
  "CupomName".toLowerCase(),
  "CupomName".toUpperCase(),
];

export const paymentsPaid = [
  IOrderChargeStatus.Paid,
  IOrderChargeStatus.Overpaid,
];

export const paymentsPendent = [
  IOrderChargeStatus.Pending,
  IOrderChargeStatus.Underpaid,
];

export const paymentsFailed = [
  IOrderChargeStatus.Failed,
  IOrderChargeStatus.Canceled,
  IOrderChargeStatus.Chargedback,
  IOrderChargeStatus.AntifraudReproved,
  IOrderChargeStatus.PaymentFailed,
  IOrderChargeStatus.Refunded,
];

export const startNumberVisa = ["4"];

export const startNumberMaster = ["51", "52", "53", "54", "55"];

export const startNumberElo = ["50", "56", "57", "58", "59"];

export const startNumberDiners = ["30", "36", "38"];

export const startNumberDiscover = ["6011", "65"];

export const startNumberJCB = ["35"];

export const startNumberAmerican = ["34", "37"];

export const creditsCards = [
  new CreditCardVisa(),
  new CreditCardMaster(),
  new CreditCardElo(),
  new CreditCardDiners(),
  new CreditCardDiscover(),
  new CreditCardJCB(),
  new CreditCardAmerican(),
];

export const errorCheckoutPaymentDefault =
  "Ops, houve uma divergência com o pagamento, revise ou altere a forma de pagamento.";

export const errorCheckoutPaymentCreditCard =
  "Cartão não aprovado. Por favor, use outro cartão ou altere a forma de pagamento.";

export const errorCheckoutPaymentPIX =
  "Não foi possível gerar o código para efetuar o pagamento. Por favor, confira os dados informados ou altere a forma de pagamento.";

export const checkoutSettingsDefault: TCustomCheckoutComponentItemSX = {
  color: grey[900],
  backgroundColor: grey[100],
  backgroundImageFixedTop: false,
  backgroundImageRepeat: false,
  backgroundImageExpand: true,
};

export const initialCheckoutList: TCustomCheckoutComponentItem[] = [
  {
    id: "4",
    name: "Checkout",
    order: 1,
    type: TCustomCheckoutComponentType.checkout,
    sx: {
      backgroundColor: grey[100],
      color: grey[900],
    },
    title: "",
    subtitle: "",
    fixedTop: false,
    children: [],
  },
];

export const customCheckoutUpdate: TCustomCheckout = {
  id: "",
  name: "Padrão",
  productId: "",
  productName: "",
  prices: [],
  defaultValue: true,
  desktopLayout: {
    settings: {
      color: "black",
      backgroundColor: "#f5f5f5",
      backgroundImageFixedTop: false,
      backgroundImageRepeat: false,
      backgroundImageExpand: true,
      backgroundImage:
        "https://visme.co/blog/wp-content/uploads/simple-backgrounds-wide-header.jpg",
      backgroundImageName: "simple-backgrounds-wide-header.jpg",
      backgroundImageExt: "jpg",
    },
    components: [
      {
        id: "80156",
        name: "Cronômetro",
        order: 1,
        type: 4,
        sx: {
          backgroundColor: "#F44747",
          color: "white",
        },
        title: "Oferta por tempo limitado",
        subtitle: "O tempo acabou!",
        fixedTop: false,
        hours: 0,
        minutes: 15,
        seconds: 0,
        milleseconds: 900000,
        children: [],
      },
      {
        id: "58571",
        name: "Imagem",
        order: 2,
        type: 3,
        sx: {
          backgroundColor: "#121212",
          color: "#fff",
        },
        title: "",
        subtitle: "",
        fixedTop: false,
        children: [],
      },
      {
        id: "2",
        name: "Main",
        order: 3,
        type: 7,
        sx: {
          backgroundColor: "#121212",
          color: "#fff",
        },
        title: "",
        subtitle: "",
        fixedTop: false,
        children: [
          {
            id: "3",
            name: "Checkout",
            order: 1,
            type: 1,
            sx: {
              backgroundColor: "#121212",
              color: "#fff",
            },
            title: "",
            subtitle: "",
            fixedTop: false,
            children: [],
          },
          {
            id: "4",
            name: "Lateral",
            order: 2,
            type: 8,
            sx: {
              backgroundColor: "#121212",
              color: "#fff",
              height: "100%",
            },
            title: "",
            subtitle: "",
            fixedTop: false,
            children: [
              {
                id: "5",
                name: "Header",
                order: 2,
                type: 0,
                sx: {
                  backgroundColor: "#121212",
                  color: "#fff",
                },
                title: "",
                subtitle: "",
                fixedTop: false,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
  mobileLayout: {
    settings: {
      color: "black",
      backgroundColor: "#73c3ac",
      backgroundImageFixedTop: false,
      backgroundImageRepeat: false,
      backgroundImageExpand: false,
    },
    components: [
      {
        id: "80156",
        name: "Cronômetro",
        order: 1,
        type: 4,
        sx: {
          backgroundColor: "#F44747",
          color: "white",
        },
        title: "Oferta por tempo limitado",
        subtitle: "O tempo acabou!",
        fixedTop: false,
        hours: 0,
        minutes: 15,
        seconds: 0,
        milleseconds: 900000,
        children: [],
      },
      {
        id: "58571",
        name: "Imagem",
        order: 2,
        type: 3,
        sx: {
          backgroundColor: "#121212",
          color: "#fff",
        },
        title: "",
        subtitle: "",
        fixedTop: false,
        children: [],
      },
      {
        id: "2",
        name: "Main",
        order: 3,
        type: 7,
        sx: {
          backgroundColor: "#121212",
          color: "#fff",
        },
        title: "",
        subtitle: "",
        fixedTop: false,
        children: [
          {
            id: "3",
            name: "Checkout",
            order: 1,
            type: 1,
            sx: {
              backgroundColor: "#121212",
              color: "#fff",
            },
            title: "",
            subtitle: "",
            fixedTop: false,
            children: [],
          },
          {
            id: "4",
            name: "Lateral",
            order: 2,
            type: 8,
            sx: {
              backgroundColor: "#121212",
              color: "#fff",
              height: "100%",
            },
            title: "",
            subtitle: "",
            fixedTop: false,
            children: [
              {
                id: "5",
                name: "Header",
                order: 2,
                type: 0,
                sx: {
                  backgroundColor: "#121212",
                  color: "#fff",
                },
                title: "",
                subtitle: "",
                fixedTop: false,
                children: [],
              },
            ],
          },
        ],
      },
    ],
  },
};
