import { NavigateFunction } from "react-router-dom"
import { isWidget } from "../constants"
import axios from "axios"
import NumberFunctions from "./NumberFunctions"
import { THostsGeolocation } from "../types/Hosts"

export const baseUrl = `${window.location.protocol}//${window.location.host}`

export const payUrl = process.env.REACT_APP_PAY_HOST

const domain = process.env.REACT_APP_COOKIE_HOST

const isHostAffiliatesPay = () => {
  if (baseUrl.includes("localhost")) {
    return true
  }
  const host = payUrl ?? ""
  return baseUrl.includes(host)
}

const isHostGoAffiliatesPay = () => {
  const host = process.env.REACT_APP_GO_HOST ?? ""
  return baseUrl.includes(host) || baseUrl.startsWith("https://go.")
}

const getUrlPayRandom = () => {
  const index = NumberFunctions.getRandomInt(3) + 1
  switch (index) {
    case 2:
      return process.env.REACT_APP_HOST_API2
    case 3:
      return process.env.REACT_APP_HOST_API3
    default:
      return process.env.REACT_APP_HOST_API1
  }
}

const getUrlRedirect = (url: string): string => {
  if (!url.trim().toLowerCase().startsWith("http")) {
    url = `https://${url}`
  }
  return url
}

const redirect = (
  urlRedirect?: string,
  urlDefault?: string,
  navigateDefault?: NavigateFunction
) => {
  if (!urlRedirect || urlRedirect.trim() === "") {
    if (urlDefault && urlDefault.trim() !== "") {
      if (navigateDefault) {
        navigateDefault(urlDefault)
      } else {
        window.location.href = getUrlRedirect(urlDefault)
      }
    }
  } else {
    window.location.href = getUrlRedirect(urlRedirect)
  }
}

const openNavigate = (path: string, navigate: NavigateFunction) => {
  if (isWidget()) {
    const url = `${process.env.REACT_APP_PAY_HOST}${path}`
    window.location.href = getUrlRedirect(url)
  } else {
    navigate(path)
  }
}

const openNewBlank = (url: string) => {
  window.open(url, "_blank", "noreferrer")
}

const copyTextToClipboard = async (text: string) => {
  try {
    var textField = document.createElement("textarea")
    textField.innerText = text
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
  } catch (e) {
    console.log(e)
    try {
      await navigator.clipboard.writeText(text)
    } catch (e) {}
  }
}

const getIpAddress = async (): Promise<string> => {
  try {
    const respIP = await axios.get("https://api.ipify.org/?format=json", {
      timeout: 2000,
    })
    return respIP?.data?.ip ?? ""
  } catch (e) {
    console.error(e)
    return ""
  }
}

const getGeolocation = async (): Promise<THostsGeolocation | null> => {
  try {
    const resp = await axios.get<THostsGeolocation | null>(
      "https://geolocation-db.com/json/"
    )
    const data = resp.data

    if (data) {
      if (data.IPv4 === "" || data.IPv4.trim().toLowerCase() === "not found") {
        data.IPv4 = await getIpAddress()
      }

      if (!data.city || data.city.trim().toLowerCase() === "not found") {
        data.city = ""
      }
      if (
        !data.country_code ||
        data.country_code.trim().toLowerCase() === "not found"
      ) {
        data.country_code = ""
      }
      if (
        !data.country_name ||
        data.country_name.trim().toLowerCase() === "not found"
      ) {
        data.country_name = ""
      }
      if (!data.postal || data.postal.trim().toLowerCase() === "not found") {
        data.postal = ""
      }
      if (!data.state || data.state.trim().toLowerCase() === "not found") {
        data.state = ""
      }
    }

    return data
  } catch (e) {
    console.log(e)
    return null
  }
}

const HostFunctions = {
  domain,
  isHostAffiliatesPay,
  isHostGoAffiliatesPay,
  getUrlRedirect,
  redirect,
  openNewBlank,
  openNavigate,
  copyTextToClipboard,
  getIpAddress,
  getUrlPayRandom,
  getGeolocation,
}

export default HostFunctions
