import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import { useState } from "react"
import Hosts from "../../../../core/functions/HostFunctions"
import React from "react"

export const PixSuccess = ({
  qrCode,
  qrCodeUrl,
}: {
  qrCode: string
  qrCodeUrl: string
}) => {
  const [copied, setCopied] = useState(false)

  const handleClick = async () => {
    await Hosts.copyTextToClipboard(qrCode)
    setCopied(true)
  }

  return (
    <Stack
      direction={"column"}
      spacing={2}
      alignItems="center"
      justifyContent="center"
      sx={{ pt: 2 }}
    >
      <Stack
        direction="column"
        spacing={2}
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Typography
          color="#38393B"
          fontSize="18px"
          lineHeight="24px"
          fontWeight={700}
        >
          Escaneie o QR Code
        </Typography>
        <img src={qrCodeUrl} alt="qrCode" />

        <Stack direction="row" spacing={2} width="100%" alignItems="center">
          <Box width="100%" height="1px" sx={{ backgroundColor: "divider" }} />
          <Typography>ou</Typography>
          <Box width="100%" height="1px" sx={{ backgroundColor: "divider" }} />
        </Stack>

        <Typography
          color="#38393B"
          fontSize="18px"
          lineHeight="24px"
          fontWeight={700}
        >
          Copie e cole o código
        </Typography>

        <TextField
          fullWidth
          aria-readonly
          size="small"
          value={qrCode}
          maxRows={1}
          inputProps={{
            "data-testid": "paymentQrCode",
          }}
          sx={{ maxWidth: "sm" }}
        />
        <Button
          variant="contained"
          size="large"
          onClick={handleClick}
          startIcon={<ContentCopyIcon />}
          data-testid="buttonCopyQrCode"
          sx={{ textTransform: "none" }}
        >
          Copiar Código
        </Button>
      </Stack>
      <Container maxWidth="sm" disableGutters>
        <Box sx={{ pt: 3 }}>
          {/*<Typography variant="body2">1. <strong>Aponte seu celular para essa tela</strong> para capturar o código</Typography>
        <Typography variant="body2">2. Abra seu <strong>aplicativo de pagamentos</strong></Typography>
        <Typography variant="body2">3. Confirme os dados e <strong>finalize o pagamento pelo aplicativo</strong></Typography>
        <Typography variant="body2">4. Será enviada uma <strong>confirmação de compra</strong> para você</Typography>*/}
          <Typography
            color="#343948"
            fontSize="16px"
            lineHeight="20px"
            fontWeight={700}
          >
            Escolha uma das opções abaixo para realizar o pagamento:
          </Typography>
          <ol type="1">
            <li>
              <Typography
                gutterBottom
                color="#343948"
                fontSize="14px"
                lineHeight="18px"
              >
                <strong style={{ fontWeight: 600 }}>Escaneie o QR Code</strong>:
                Abra o aplicativo do seu banco, selecione a opção de “Pagar com
                QR Code” e aponte seu celular para o código na tela.
              </Typography>
            </li>
            <li>
              <Typography
                gutterBottom
                color="#343948"
                fontSize="14px"
                lineHeight="18px"
              >
                <strong style={{ fontWeight: 600 }}>
                  Copie e cole o código
                </strong>
                : Clique em “Copiar Código” e cole na opção “PIX Copia e Cola”
                do seu aplicativo bancário.
              </Typography>
            </li>
            <li>
              <Typography
                gutterBottom
                color="#343948"
                fontSize="14px"
                lineHeight="18px"
              >
                Confirme os dados do pagamento e finalize a compra no
                aplicativo.
              </Typography>
            </li>
            <li>
              <Typography
                gutterBottom
                color="#343948"
                fontSize="14px"
                lineHeight="18px"
              >
                Você receberá uma confirmação por e-mail assim que o pagamento
                for processado.
              </Typography>
            </li>
          </ol>
        </Box>
      </Container>

      {/*<Alert sx={{ my: 1 }} severity="info">
        Você também pode finalizar o pagamento copiando e colando o código em
        seu aplicativo.
      </Alert>*/}

      <Snackbar
        open={copied}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        message="Código copiado"
        autoHideDuration={2000}
        onClose={() => setCopied(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setCopied(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          Código copiado!
        </Alert>
      </Snackbar>
    </Stack>
  )
}
